import React from 'react'
import FAQ_image from "@images/FAQ_image.png";
import { DownCollapse, UpCollapse } from '@images/sidebarIcons';

const FAQContents = [
    {
        title: "Accordion #1",
        content: `<strong>This is the First item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.`
    },
    {
        title: "Accordion #2",
        content: `<strong>This is the Second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.`
    },
    {
        title: "Accordion #3",
        content: `<strong>This is the Third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.`
    }, 
]


const Accordion=()=>{

    return(
        <div className="hs-accordion-group my-10 max-xl:mx-3">
            {
                FAQContents.map((question,index)=>(
                <div className="hs-accordion my-3" id={`hs-basic-with-title-and-arrow-stretched-heading-${index}`} key={question.title}>
                    <button className="bg-white hs-accordion-toggle hs-accordion-active:text-blue-600 p-3 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-start text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none" 
                    aria-expanded="true" aria-controls={`hs-basic-with-title-and-arrow-stretched-collapse-${index}`}>
                    {question.title}
                    <DownCollapse />
                    <UpCollapse />
                    </button>
                    <div id={`hs-basic-with-title-and-arrow-stretched-collapse-${index}`} 
                        className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300 bg-[#F8F8F8] rounded-lg p-4" 
                    aria-labelledby={`hs-basic-with-title-and-arrow-stretched-heading-one`}>
                    <p className="text-gray-800" dangerouslySetInnerHTML={{ __html: question.content }}></p>
                    </div>
                </div>
                ))
            }
        </div>
    )
}

const FAQ = () => {
  return (
        <section id="faq" className="my-7 max-sm:w-[150%]">
            <div className="grid grid-flow-col max-xl:grid-flow-row grid-cols-2 max-xl:grid-cols-1">
                <div className="xl:max-w-[43vw] max-xl:hidden">
                    <img src={FAQ_image} alt="faq bot" className="scale-75"/>
                </div>
                <div className="xl:max-w-[43vw] max-xl:w-[100%]">
                    <h1 className="text-[#D51037] text-4xl  font-semibold ">Frequently Asked Questions</h1>
                    <p className="font-medium xl:max-w-[80%] text-[1.1rem]">
                        Get quick answers to the most common questions about JuristBot. 
                        From legal drafting to complex case handling, explore how JuristBot can assist you in your legal journey. 
                        If you don't find what you're looking for, feel free to reach out to our support team!
                    </p>
                    <Accordion />
                </div>
            </div>
        </section>
  )
}

export default FAQ