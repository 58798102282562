import React, { useState,useEffect,useRef, Fragment } from "react";
import fileUpload from "@images/file_upload.png";
import { LogoutIcon,CloseIcon } from "@images/sidebarIcons";
import Select from 'react-select';
import { isValidUrl,Toaster } from "@constants/appConstants";

const createObjectURL=(img)=>{
  try {
    return URL.createObjectURL(img)    
  } catch (error) {
    return img
  }
}

const FileUpload = () => {
  const [files, setFiles] = useState({});
  const [dragCounter, setDragCounter] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const hiddenInputRef = useRef();

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;    
    handleFiles(selectedFiles);
  };

  const handleFiles = (selectedFiles) => {
    const newFiles = { ...files };
    Array.from(selectedFiles).forEach((file) => {
      const objectURL = URL.createObjectURL(file);
      newFiles[objectURL] = file;
    });
    setFiles(newFiles);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragCounter(0);
    setIsDragging(false);
    const droppedFiles = e.dataTransfer.files;
    handleFiles(droppedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    if (e.dataTransfer.items && e.dataTransfer.items[0].kind === "file") {
      setDragCounter(dragCounter + 1);
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragCounter(dragCounter - 1);
    if (dragCounter === 1) {
      setIsDragging(false);
    }
  };

  const handleDelete = (objectURL) => {
    const newFiles = { ...files };
    delete newFiles[objectURL];
    setFiles(newFiles);
  };
  const renderFile = (file, objectURL,handleDelete) => {
    const isImage = file.type.match("image*");
    return (
      <li key={objectURL} className="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24">
        <article className="group hasImage w-full h-full rounded-md focus:outline-none bg-gray-100 cursor-pointer relative shadow-sm">
          {isImage && (
            <img src={objectURL} alt={file.name} className="img-preview w-full h-full object-cover rounded-md" />
          )}
          <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
            <h1 className="flex-1">{file.name}</h1>
            <div className="flex">
              <button
                className="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md"
                onClick={() => handleDelete(objectURL)}
              >
                <svg
                  className="pointer-events-none fill-current w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path className="pointer-events-none" d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                </svg>
              </button>
            </div>
          </section>
        </article>
      </li>
    );
  };
  

  return (
    <div className="  px-2 m-3">
      <div className="container max-w-[50%] h-[50vh]">
        <article
          aria-label="File Upload Modal"
          className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          {isDragging && (
            <div id="overlay" className="w-full h-full absolute top-0 left-0 z-50 flex flex-col items-center justify-center rounded-md bg-gray-300">
              <i>
                <svg className="fill-current w-12 h-12 mb-3 text-blue-700" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z" />
                </svg>
              </i>
              <p className="text-lg text-blue-700">Drop files to upload</p>
            </div>
          )}

          <section className="h-full overflow-hidden py-8 px-3 w-full  flex flex-col">
            <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
              <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">
                <span>Drag and drop your</span>&nbsp;<span>files here or</span>
              </p>
              <input
                id="hidden-input"
                type="file"
                multiple
                className="hidden"
                ref={hiddenInputRef}
                onChange={handleFileChange}
              />
              <button
                className="mt-2 rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none"
                onClick={() => hiddenInputRef.current.click()}
              >
                Upload a file
              </button>
            </header>

            <h1 className="py-1 font-semibold sm:text-lg text-gray-900">To Upload</h1>

            <ul id="gallery" className={`flex flex-1 flex-wrap m-1 ${Object.keys(files).length > 0 ? "py-6" : ""} min-h-[20vh] overflow-y-scroll `}>
              {Object.keys(files).length === 0 ? (
                <li id="empty" className="h-full w-full text-center flex flex-col items-center justify-center">
                  <img
                    className="mx-auto w-32"
                    src={fileUpload}
                    alt="no data"
                  />
                  <span className="text-small text-gray-500">No files selected</span>
                </li>
              ) : (
                Object.keys(files).map((objectURL) => renderFile(files[objectURL], objectURL,handleDelete))
              )}
            </ul>
          </section>
        </article>
      </div>
    </div>
  );
};

const SingleFileUpload=({
    image,accept="image/*",maxSize=4,imageClass,fileInputRef,
    handleChange,handleReset,showButtons=true
  })=>{
  const onFileChange=(file)=>{
    
    if( //file type check
      (accept === "image/*" && file.type.startsWith("image/"))
      ||
      file.type.includes(accept.replace(".",""))
      ){
        //file size check
        if(file.size <= (maxSize*(10**6)) ){
          handleChange(file)
        }else{
          Toaster("error",`Upload a file just of size less than ${maxSize}MB`)
        }
      }else{
        Toaster("error",`Just Upload only ${accept} files`)
      }
  }

  return(
    <div id="single-file" className="flex flex-wrap items-center gap-3">
      <div>
        {/** if it is not a valid http url , 
         *  then either create object url for fake path, 
         * 
         * if it as an http url,  or
         * if it is a default source image from `import` then
         * place it directly
        */}
        <img src={ ! isValidUrl(image) ? createObjectURL(image) : image} 
            alt="profile" className={`inline ${imageClass}`}/>
      </div>
      <div className="grow cursor-pointer">
        <div className="flex items-center gap-x-2">
          <input type='file' accept={accept} className='hidden' ref={fileInputRef}  onChange={(e)=>onFileChange(e.target.files[0])}/>
         { showButtons &&
          <Fragment>
          <span type="button" onClick={()=>fileInputRef?.current.click()}
            className="py-1 px-3 inline-flex items-center gap-x-2 text-[16px] font-medium rounded-lg border border-transparent 
            bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 ">
            <LogoutIcon className="-rotate-90 border-0" stroke="white"  strokeWidth="1.25"/>
            Upload photo
          </span>
          <span type="button" onClick={handleReset}
            className="py-1 px-3 inline-flex items-center gap-x-2 text-[16px] font-semibold rounded-lg border 
            border-gray-400 bg-white text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50" >
            Reset
          </span> 
          </Fragment>
          }
        </div>
      </div>
    </div>
  )
}

const Loader = ({children}) => {
  return (
    children ||
    <div className="animate-spin mx-auto flex self-center size-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"  aria-label="loading">
        <span className="sr-only">Loading...</span>
    </div>
  )
}

const footerButtonClass = "py-2 px-3 inline-flex items-center gap-x-2 text-[1rem] font-semibold rounded-lg border border-gray-400 bg-[#FFC092] text-gray-800 shadow-lg"
const ScrollableModal=({modalState,onClose=null,title="Modal title",content="No Content Provided",footerButtons,})=>{
    
    useEffect(()=>{
      if(modalState)
        document.body.style.overflow = "hidden";
      else
        document.body.style.overflow = "initial";
    },[modalState]);

  return(
    <Fragment>
      { modalState ?
        <div className={`fixed container max-w-full min-w-full inset-0 z-[70] bg-[#fab68628] bg-opacity-80 backdrop-blur-sm transition-all duration-1000  
                    ${modalState ? " visible opacity-100" : "invisible opacity-0" }`}>
          <div className="absolute inset-0 z-[70] flex items-center justify-center overflow-y-auto">
            <div className=" flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto   ">

              <div className="flex justify-between items-center py-3 px-4 border-b ">
                <h3 id="hs-vertically-centered-scrollable-modal-label" className="font-bold text-gray-800 ">
                  {title}
                </h3>
                <button onClick={onClose} type="button" 
                  className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 
                  text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200">
                  <span className="sr-only">Close</span>
                  <CloseIcon className="shrink-0 size-4 text-red-600 font-extrabold" 
                              strokeWidth="5"/>
                </button>
              </div>
              {
                content 
              }
              <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t ">
              {
                footerButtons &&
                <Fragment>
                  <button type="button" onClick={ () => {
                    if(footerButtons.close)
                      footerButtons.close(); 
                    return onClose  ? onClose() : null
                  }} 
                  className={footerButtonClass+ " bg-white"} 
                  >
                  Close
                  </button>
                  <button type="button" onClick={footerButtons.save} 
                  className={footerButtonClass} 
                  >
                    Save changes
                  </button>
                </Fragment>
              }
              </div>
            </div>
          </div>
        </div> : null
          }
      </Fragment>
  );
};

const SelectReact = ({
  label,
  options = [],
  onChange,
  value,
  isDisabled = false,
  isLoading = false,
  onInputChange=null,
  onLoadMore,
  hasMore,
  inputValue,
  className,
  isSearchable=true,
  ...field
}) => {
  const [valueState, setValueState] = useState();
  const [inputChange,setInputChange] = useState("");
  
  useEffect(()=>{
    if(onInputChange)
        onInputChange(inputChange)
  },[inputChange,onInputChange]);

  useEffect(() => {
    if ( (value && options?.length > 0) || value==="") {
      const selectedOption = options?.find((option) => option.value === value);
      setValueState(selectedOption||value);
    }
  }, [value,options, options?.length]);

 
  return (
    <div className={`w-full ${className}`}>
      <style jsx={"true"}> {/*NOSONAR*/}
        {
          `
            [class^="css-"][class*="-control"] {
              border: 0;
              border-radius: 1rem;
            }
            [class^="css-"][class*="-control"]:focus{
              outline: none;
              border: 0;
            }
          `
        }
      </style>
      <Select
        value={valueState}
        options={options || []}
        isDisabled={isDisabled}
        onInputChange={setInputChange}
        inputValue={inputValue}
        isLoading={isLoading}
        onChange={(value) => {
          onChange(value);
          setValueState(value);
        }}
        placeholder={label}
        onMenuScrollToBottom={()=>{
          if(onLoadMore)
          if(options.length < options[0].total_count)
            onLoadMore()
        }}
        // menuPortalTarget={document.body}
        menuPlacement="auto"
        isClearable
        className="rounded-2xl border-[1px] m-3 border-gray-400 outline-none focus:ring-0"
        isSearchable={isSearchable}
        {...field}
      />
    </div>
  );
};

const TextTruncateTooltip=({content,className,...props})=>{
  const cellRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  // Check if the text is truncated
  useEffect(() => {
    const cell = cellRef.current;
    if (cell) {
      setIsTruncated(cell.scrollWidth > cell.clientWidth);
    }
  }, [content]);
  
  const combinedRef=(element)=>{
      cellRef.current = element;
      if(props.ref)
          props.ref.current = element;
  }
  
  const element = 'div';


  return React.createElement(element,{ref:combinedRef,className:className+ " group ",...props},
      <Fragment>  
      <span className="relative">{content}</span>
          {isTruncated && (
            <div className="relative z-10 bg-black opacity-0  transition-opacity duration-200 group-hover:opacity-100 p-1 rounded shadow-lg w-full">
            <p 
            className="inline  left-0 transform -translate-y-full mb-2 px-2 py-1 bg-black text-white  h-fit leading-none  text-wrap
                     text-sm  max-w-[100px] ">
              {content}
            </p>
            </div>
           )}    
      </Fragment>  
  )
  
}

const ToolTip=({element="span",children,...props})=>{
  const comBinedClassName= `invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-opacity -translate-y-full 
                            duration-1000 bg-black text-white absolute p-1 text-sm font-light rounded-lg `+props.cssclass
 
  return React.createElement(
        element,
        {
          className: comBinedClassName.replace("\n"," "),
          ...props
        },
        children
      )
}

const StdButton=(
        {children,className,...props}
        )=><button {...props} className={"cursor-pointer rounded-full font-semibold px-6 py-1 m-1.5 shadow-xl bg-[#FFC092] "+className}>
              {children}
            </button>

export { FileUpload,SingleFileUpload,ScrollableModal,ToolTip,
        SelectReact,TextTruncateTooltip,Loader,StdButton };


