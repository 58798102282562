import Pagination from '@components/Pagination';
import PricingPlans from '@components/PricingPlans'
import { TableCellWithToolTip } from '@components/Table';
import { SubscriptionIcon, TransactionHistory } from '@images/sidebarIcons';
import { useAuth } from 'features/contexts/AuthContext';
import React, { useEffect, useState } from 'react'

const AdminSubscription = () => {
  let ignorePlans = ["Basic"];
  
  const {user} = useAuth();

  const columns=[{key:"invoiceId",name:"Invoice ID"},{key:"plan",name:"Purchased Plan"},
                {key:"pDate",name:"Purchased Date"},{key:"eDate",name:"Expired Date"}]
  
  const [transactionHist,setTransactionHist] = useState([
    {"invoiceId": "INV-1",plan:"Basic",pDate:"2024-08-01",eDate:"2024-09-01"},
    {"invoiceId": "INV-2",plan:"Premium",pDate:"2024-09-02",eDate:"2024-10-02"},
    {"invoiceId": "INV-3",plan:"Elite",pDate:"2024-10-03",eDate:"2024-11-03"},
    {"invoiceId": "INV-4",plan:"Elite",pDate:"2024-11-01",eDate:"2024-12-03"},
    {"invoiceId": "INV-5",plan:"Elite",pDate:"2024-12-04",eDate:"2024-01-04"},
    {"invoiceId": "INV-6",plan:"Basic",pDate:"2024-08-01",eDate:"2024-09-01"},
    {"invoiceId": "INV-7",plan:"Premium",pDate:"2024-09-02",eDate:"2024-10-02"},
    {"invoiceId": "INV-8",plan:"Elite",pDate:"2024-10-03",eDate:"2024-11-03"},
    {"invoiceId": "INV-9",plan:"Elite",pDate:"2024-11-01",eDate:"2024-12-03"},
    {"invoiceId": "INV-10",plan:"Elite",pDate:"2024-12-04",eDate:"2024-01-04"},
    {"invoiceId": "INV-11",plan:"Basic",pDate:"2024-08-01",eDate:"2024-09-01"},
    {"invoiceId": "INV-12",plan:"Premium",pDate:"2024-09-02",eDate:"2024-10-02"},
    {"invoiceId": "INV-13",plan:"Elite",pDate:"2024-10-03",eDate:"2024-11-03"},
    {"invoiceId": "INV-14",plan:"Elite",pDate:"2024-11-01",eDate:"2024-12-03"},
    {"invoiceId": "INV-15",plan:"Elite",pDate:"2024-12-04",eDate:"2024-01-04"},
    {"invoiceId": "INV-16",plan:"Basic",pDate:"2024-08-01",eDate:"2024-09-01"},
    {"invoiceId": "INV-17",plan:"Premium",pDate:"2024-09-02",eDate:"2024-10-02"},
    {"invoiceId": "INV-18",plan:"Elite",pDate:"2024-10-03",eDate:"2024-11-03"},
    {"invoiceId": "INV-19",plan:"Elite",pDate:"2024-11-01",eDate:"2024-12-03"},
    {"invoiceId": "INV-20",plan:"Elite",pDate:"2024-12-04",eDate:"2024-01-04"},
  ]);
  const [currentPage,setCurrentPage] = useState(1);
  const [totalCount,setTotalCount] = useState(400);
  const [pageSize,setPageSize] = useState(10);

  return (
    <div className='mx-auto'>
      {/*switching tabs*/}
      <div className="border-b border-gray-200 mt-6">
      <style>{`.active .active-icon .st0{fill:#FF6309 !important;}`}</style>
          <nav className="flex gap-x-1"  aria-label="Tabs" role="tablist" aria-orientation="horizontal"> {/*NOSONAR*/}
              <button type="button" 
                  className=" hs-tab-active:font-semibold text-xl hs-tab-active:border-[#FF6309] hs-tab-active:text-[#FF6309] py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent  whitespace-nowrap text-gray-500 hover:text-[#FF6309] focus:outline-none disabled:opacity-50 disabled:pointer-events-none active" 
                  id="tabs-with-underline-item-1" aria-selected="true" data-hs-tab="#tabs-with-underline-1" aria-controls="tabs-with-underline-1" role="tab">
                <SubscriptionIcon className="active-icon size-8"/>Subscription Plans
              </button>
              <button type="button" 
                  className="hs-tab-active:font-semibold text-xl hs-tab-active:border-[#FF6309] hs-tab-active:text-[#FF6309] py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent  whitespace-nowrap text-gray-500 hover:text-[#FF6309] focus:outline-none  disabled:opacity-50 disabled:pointer-events-none" 
                  id="tabs-with-underline-item-2" aria-selected="false" data-hs-tab="#tabs-with-underline-2" aria-controls="tabs-with-underline-2" role="tab">
                <TransactionHistory className="active-icon size-8"/> Transaction
              </button>
          </nav>
      </div>
      {/*tab content*/}
      <div className="container">
        <div id="tabs-with-underline-1" role="tabpanel" aria-labelledby="tabs-with-underline-item-1">
          <PricingPlans subscriptionPage={ignorePlans}/>
          <form>
            <script src="https://cdn.razorpay.com/static/widget/subscription-button.js" data-subscription_button_id="pl_PJ93E6zIfD9Fju" data-button_theme="brand-color" async> </script> 
          </form>
        </div>
        <div id="tabs-with-underline-2" className="hidden" role="tabpanel" aria-labelledby="tabs-with-underline-item-2">
          <div className='mt-3'>
            <div className='bg-white rounded-2xl shadow-xl grid grid-cols-10 min-h-fit'>
              <div className='col-span-3 grid grid-cols-2 p-5 text-base'>
                <div className='col-span-2 ps-3 font-medium text-2xl'>Recent Subscription</div>
                <div className='flex flex-col gap-2'>
                  <div className='px-3 py-2.5 text-left font-medium'>Current Status</div>
                  <div className='px-3 py-2.5 text-left font-medium'>Current Plan</div>
                  <div className='px-3 py-2.5 text-left font-medium'>Purchase Date</div>
                  <div className='px-3 py-2.5 text-left font-medium'>Expiration Date</div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='bg-green-100 rounded-full px-3 py-2.5 text-green-600 text-center font-bold'>Active</div>
                    <div className='px-3 py-2.5 rounded-full bg-yellow-100 text-yellow-600 text-center font-bold'>{user?.company_plan}</div>
                    <div className='px-3 py-2.5 text-left font-medium'>01-09-2024</div>
                    <div className='px-3 py-2.5 text-left font-medium'>30-09-2024</div>
                </div>
              </div>
              <div className='col-span-7'>
              <div className="h-full w-px bg-gray-300 mx-4 inline-block"></div>
                <div className="inline-block w-[95%] rounded-2xl relative z-30 overflow-x-hidden max-h-[80vh] overflow-y-scroll shadow-md">
                  <table className="rounded-2xl table-fixed relative min-w-full">
                        <thead className="rounded-2xl">
                          <tr className="bg-[#FFC092] rounded-2xl sticky top-0">
                                {
                                    columns.map((col)=>(
                                        <TableCellWithToolTip key={col.name} 
                                        className="p-4 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]"
                                        content={col.name} header/>
                                    ))
                                }
                                <th className="p-4 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                transactionHist.map((row,rowIndex)=>(
                                    <tr key={row.invoiceId} className={`p-2 ${rowIndex%2 === 0 && " bg-white "}`}>
                                        {
                                            columns.map((col)=>(
                                              <TableCellWithToolTip key={col.key} 
                                              className="p-4 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]"
                                              content={row[col.key]} />
                                            ))
                                        }
                                        <td className="p-2 text-center">
                                          <button className="rounded-3xl bg-[#ffc092] px-4 py-2 fonte-semibold shadow-lg">
                                            View Invoice
                                          </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        <tfoot>
                          <tr className="bg-white border-t-[1px] border-gray-300 sticky bottom-0">
                            <td className='text-center p-4' colSpan={columns.length+1}>
                                <Pagination
                                    currentPage={currentPage}
                                    totalCount={totalCount}
                                    pageSize={pageSize}
                                    onPageSizeChange={setPageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminSubscription