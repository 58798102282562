import { useAuth } from 'features/contexts/AuthContext';
import React from 'react'


const PlanDetails = [
    {
        planName: "Basic",
        amount: "0",
        description: `Our Basic Membership is perfect for lead generation via WhatsApp, covering event creation, menu selection for customers, and efficient client management for businesses.`,
        perks: ["Client Management","Event Creation & Menu Selection","Lead Generation"],
        bgColor: " from-[#f98889] to-[#a73b3d] "
    },
    {
        planName: "Premium",
        amount: "1500",
        description: `Our Premium Membership includes all Basic plan features, plus menu consolidation, streamlining ingredient planning and execution for faster and more efficient operations.`,
        perks: ["All In Basic Plan Plus","Menu Consolidation for Efficient Ingredient Planning"],
        bgColor: " from-[#f1a36c] to-[#ff6d03] "
    },
    {
        planName: "Elite",
        amount: "2000",
        description: `Our Elite Membership includes all Premium features,  comprehensive vendor management , 
                        customized PDFs to deliver it vendors,simplifying coordination for seamless event execution.`,
        perks: ["All In Premium Plan Plus","Vendor Management with  PDF Reports"],
        bgColor: " from-[#7fcd66] to-[#4d9f32] "
    },
    
]


const PricingPlans = ({subscriptionPage=[]}) => {
    
    const {user} = useAuth();
    const isPurchased=(planName)=>user?.company_plan===planName

  return (
        <section id="subscribe" className={`relative max-md:w-[150%] ${subscriptionPage ? "my-0" : "my-14"} p-4 mx-auto`}>
            <style>
                {
                    `
                    #subscribe ul{
                        list-style: disc;
                     }
                    `
                }
            </style>
            { !subscriptionPage.length &&
            <div className='mb-6'>
                <p className="text-center text-[#D51837] text-4xl font-bold">Pricing Plans</p>
                <p className="text-center font-medium text-lg text-wrap w-[70%] mx-auto">
                    Enhance your AI chatbot with more features, workflows, and automations through plug-and-play integrations.
                </p>
            </div>
            }
            <div className="grid grid-flow-col grid-cols-3 max-xl:grid-cols-1 max-xl:grid-flow-row p-4 mx-auto   items-stretch justify-items-center content-center ">
                {
                    PlanDetails.map((item,index)=>{
                        if( !subscriptionPage.length || (!subscriptionPage?.includes(item.planName) ) )
                        return(
                            <div key={item.planName} className='relative'>
                                <div 
                                    className={` text-3xl  w-[60%] top-8 h-[5rem] z-20 text-center  overflow-visible border-[10px] border-[#FEF1E1] mx-auto 
                                        rounded-2xl relative py-2 px-3 mb-1 text-white font-bold bg-gradient-to-b ${item.bgColor}`}>
                                    ₹{item.amount}
                                </div>
                            <div className={`rounded-3xl text-center relative font-bold text-white max-w-[24rem] md:max-h-screen h-auto bg-gradient-to-b ${item.bgColor}  p-6 py-12`}>
                                <p className="text-3xl font-semibold">{item.planName}</p>
                                <hr className="h-[2px] w-[80%] mx-auto bg-white"/>
                                <div className="font-medium">Description & Benefits</div>
                                <p className="w-[80%] text-wrap mx-auto">{item.description}</p>
                                <hr className="h-[2px] w-[80%] mx-auto bg-white"/>
                                 <ul className="text-start ml-4 mt-6 px-14 w-[115%]">
                                    {
                                        item.perks.map((perk)=>{
                                            return(
                                                <li key={perk}>{perk}</li>
                                            )
                                        })
                                    }
                                 </ul>
                                 <button className="bg-white text-black rounded-full w-[80%] block p-3 mt-[5%] mx-[10%] relative top-[5%]
                                                      cursor-pointer disabled:cursor-not-allowed disabled:bg-gray-500 disabled:text-white"
                                    disabled={isPurchased(item.planName)} 
                                    title={isPurchased(item.planName) ? "Already Purchased" : item.planName}
                                    >
                                    { isPurchased(item.planName) ? "Purchased" : "BUY NOW"}
                                </button>
                            </div>
                            </div>
                        )
                        else return null
                    })
                }
            </div>
        </section>
  )
}

export default PricingPlans