import React, { Fragment,useCallback,useEffect,useState } from 'react'
import { TableCellWithToolTip } from '@components/Table';
import axiosInstance from 'apis/config';
import Pagination from '@components/Pagination';
import { Toaster } from '@constants/appConstants';
import { StdButton,ScrollableModal, SelectReact, ToolTip } from '@components/UtilityComponents';
import { useAuth } from 'features/contexts/AuthContext';
import { DeleteIcon, EditIcon } from '@images/sidebarIcons';



const DishModal=({content={},setModalState})=>{
    const selectedInit={Item_Ingredient_id:"",Vendor_type:"",Ingredient_Name:"",Quantity_value:"",UOM:""}
    const [dishName,setDishName] = useState(content.Menu_Name || "");
    const [category,setCategory] = useState(content.Menu_Type || "Veg");
    const [ingredientTypeOptions, setIngredientTypeOptions] = useState([]);
    const [ingredientOptions,setIngredientOptions] = useState([]);
    const [uomList,setUomList] = useState([]);
    const [ingredientsList,setIngredientsList] = useState([]);
    const [deletedIngredients,setDeletedIngredients] = useState([]);
    const [selectedData,setSelectedData] = useState({...selectedInit})
    const {user} = useAuth();

    const getIngredientTypes=()=>{
        axiosInstance.get("business/vendors/getVendorType",{
                        params: { Limit: 100, offset: 0 }
                    })
                    .then(setIngredientTypeOptions)
    }

    const getIngredients=async(vId)=>{
       await axiosInstance.get("menus/ingredients-by-type/"+vId)
                        .then((ingds)=>{
                            setIngredientOptions(ingds);
                        })
                        .catch((err)=>Toaster("error",err))
    }
    
    const getUom=()=>{
        axiosInstance.get("menus/get_uom")
                    .then(setUomList)
    };

    const getDishIngredients=useCallback(()=>{
            if(content.Menu_Id){
                    axiosInstance.get("menus/ingredients-by-dish/"+content.Menu_Id)
                        .then((resp)=>{
                            if(resp.length > 0){
                                let ingredients = resp.map((ing)=>(
                                                    {   
                                                    Item_Ingredient_id: ing.Item_Ingredient_id,
                                                    Vendor_type: {Vendor_type: ing.Vendor_type,Vendor_Id: ing.Vendor_Id},
                                                    Ingredient_Name: {Ingredient_Name: ing.Ingredient_Name,Ingredient_id: ing.Ingredient_id},
                                                    Quantity_value: {label: ing.Quantity_value,value: ing.Quantity_value},
                                                    UOM: {UOM: ing.UOM,ID: ing.UOM_id}
                                                    }
                                                ));
                                setIngredientsList(ingredients);
                            }
                        })
                        .catch(console.log)
            }
        },[content.Menu_Id]);

    useEffect(()=>{
        getIngredientTypes();
        getUom();
    },[])

    useEffect(()=>{
        if(user?.company_plan === "Elite"){
            getDishIngredients();
        }
    },[user?.company_plan,getDishIngredients])


    const addToTable=()=>{
        let {Item_Ingredient_id,...selectedCopy} = selectedData
        let isEmpty = Object.values(selectedCopy).some(el=>Boolean(el)===false)
        if(isEmpty){
            Toaster("error","All Fields are required")
        }else{
            setIngredientsList((prev)=>([...prev,selectedData]));
            setSelectedData({...selectedInit})
        }
    }

    const handleDeleteFromTable=(rowIndex)=>{
        let ingredientsCopy = ingredientsList.slice();
        if(ingredientsCopy[rowIndex].Item_Ingredient_id){ // deleted list only if it has item_ingredient_id from server data
            setIngredientsList(ingredientsCopy.filter((_,index)=>index!==rowIndex));
        }
        setDeletedIngredients((prev)=>[...prev,ingredientsCopy[rowIndex]]);
    }

    const handleEditFromTable=async(rowIndex)=>{
        let ingredientsCopy = ingredientsList.slice();
        let selectedCopy = ingredientsCopy[rowIndex]
        await getIngredients(selectedCopy.Vendor_type.Vendor_Id);
        setSelectedData(selectedCopy);
        setIngredientsList(ingredientsCopy.filter((_,index)=>index!==rowIndex));
    };

    const handleSaveDish=()=>{
        let jsonBody = {
            dishId: content.Menu_Id,
            dishName,
            category,
            deletedIngredients: deletedIngredients.map((it)=>it.Item_Ingredient_id).join(","),
            ingredientsList: ingredientsList.map((itm)=>(
                {
                    Item_Ingredient_id: itm.Item_Ingredient_id,
                    Ingredient_id: itm.Ingredient_Name.Ingredient_id,
                    Quantity_value: parseFloat(itm.Quantity_value.value),
                    UOM: itm.UOM.ID
                }
            ))
        };

        const HTTP_METHOD = content.Menu_Id ? "put" : "post"
        axiosInstance[HTTP_METHOD]("menus/dish",jsonBody)
                .then((resp)=>{
                    if(resp.some(it=>it?.message?.includes("success"))){
                        let message = HTTP_METHOD === "put" ? "Dish Updated Successfully": "New Dish Added Successfully";
                        Toaster("success",message);
                        setTimeout(()=>setModalState(false),1500);
                    }
                })
                .catch(console.error)
    };
    
    const handleFieldChange=(field,value)=>{
        let selectedDataCopy = {...selectedData};
        selectedDataCopy = { ...selectedDataCopy, [field]:value };
        if(field==="Vendor_type"){
            if(value){
                getIngredients(value.Vendor_Id)
            }
            selectedDataCopy = {...selectedDataCopy,Ingredient_Name: ""}
            setIngredientOptions([])
        }
        setSelectedData(selectedDataCopy);
    };

    return(
            <div className=' bg-[white] w-full h-full px-32 py-16'>

                <div className='min-w-full font-medium shadow-2xl p-8 bg-white rounded-xl grid grid-flow-row grid-cols-2 gap-y-9 m-3'>
                    <div>Categories</div>
                    <div className='flex'>
                        {
                            ["Veg","Non Veg"].map((type)=>(
                                <StdButton key={type} 
                                    className={` rounded-md px-[14px!important] py-[8px!important] shadow-four-sides
                                                 w-full h-fit text-nowrap ${type!==category ? "bg-white":""}`} 
                                    onClick={()=>setCategory(type)}>
                                    {type}
                                </StdButton>
                            ))
                        }
                    </div>
                    <div>
                        Enter Dish Name
                    </div>
                    <input value={dishName} onChange={(e)=>setDishName(e.target.value)}
                        className='p-1.5 rounded-3xl outline-none border border-gray-400 shadow-2xl' />
                </div>
                { /*ingredients should display only when plan is Elite*/
                    user.company_plan === "Elite" &&
                <div className='min-w-full shadow-2xl bg-white rounded-xl  p-10  m-3'>
                    <div className='text-xl font-medium'>Add Ingredient</div>
                    <div className='grid grid-flow-row grid-cols-[1fr_1fr_1fr_1fr_0.1fr] gap-8 place-items-center m-0'>
                        
                        <SelectReact label={"Ingredient Type"} options={ingredientTypeOptions} 
                                    value={selectedData.Vendor_type} onChange={(value)=>handleFieldChange("Vendor_type",value)}
                                    getOptionLabel={(opt)=>opt.Vendor_type} getOptionValue={(opt)=>opt.Vendor_Id}/>
                        
                        <SelectReact label={"Ingredient Name"} options={ingredientOptions}
                                    value={selectedData.Ingredient_Name} onChange={(value)=>handleFieldChange("Ingredient_Name",value)}
                                    getOptionLabel={(opt)=>opt.Ingredient_Name} getOptionValue={(opt)=>opt.Ingredient_id}
                                    />
                        
                        <input placeholder='Quantity' type="number" className='p-1.5 rounded-3xl outline-none h-10 border border-gray-400 shadow-2xl' 
                                value={selectedData.Quantity_value.label || ""} 
                                onKeyDown={(e)=>{if(e.key === "e" || e.key === "E"){ e.preventDefault();}}}
                                onChange={(e)=> {
                                    const { value} = e.target;
                                    if( (!isNaN(value) && value > 0) || value===""){
                                        handleFieldChange("Quantity_value",{label:value,value})
                                    }
                                }}
                                />
                        
                        <SelectReact label={"UOM"} options={uomList} 
                            value={selectedData.UOM} onChange={(value)=>handleFieldChange("UOM",value)}
                            getOptionLabel={(opt)=>opt.UOM} getOptionValue={(opt)=>opt.ID}/>

                        <StdButton className={"group mx-auto text-xl py-[1px!important]"} onClick={addToTable}>
                            +
                            <ToolTip>Add New Ingredient</ToolTip>
                        </StdButton>   
                    </div>
                    
                  
                    <div className="rounded-2xl overflow-x-hidden max-h-[250px] overflow-y-scroll">
                        <table className="rounded-2xl table-fixed relative min-w-full">
                            <thead>
                                <tr className="bg-[#FFC092] rounded-2xl sticky top-0">
                                    <th className="p-2.5 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]">Ingredient type</th>
                                    <th className="p-2.5 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]">Ingredient Name</th>
                                    <th className="p-2.5 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]">Quantity</th>
                                    <th className="p-2.5 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]">UOM</th>
                                    <th className="p-2.5 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ingredientsList.map((row,rowIndex)=>{
                                        return (<tr key={row["Ingredient_Name"]["Ingredient_id"]} className={`p-2 ${rowIndex%2 !== 0 && " bg-[#FEF1E1] "}`}>
                                                    {
                                                        ["Vendor_type","Ingredient_Name","Quantity_value","UOM"].map((col)=>{
                                                            
                                                            return <td className='className="p-2.5 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]' 
                                                                        key={col}>
                                                                        { row[col].label || row[col][col]}
                                                                    </td>
                                                        })
                                                    }
                                                    <td className='text-center'>
                                                        <StdButton className={"group mx-auto text-sm py-[1px!important]"} onClick={()=>handleEditFromTable(rowIndex)}>
                                                            <EditIcon />
                                                            <ToolTip cssclass="text-nowrap -translate-x-full">Edit this</ToolTip>
                                                        </StdButton>
                                                        <StdButton className={"group mx-auto text-sm py-[1px!important]"} onClick={()=>handleDeleteFromTable(rowIndex)}>
                                                            <DeleteIcon />
                                                            <ToolTip cssclass="text-nowrap -translate-x-full">Delete this</ToolTip>    
                                                        </StdButton>
                                                    </td>
                                                </tr>)  
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                }
                <div className='flex mx-auto items-center justify-center'>
                    <StdButton className={"rounded-md py-2"} onClick={handleSaveDish}>
                        Save
                    </StdButton>
                </div>

            </div>
    )
}

const AdminDishes = () => {
    const [dishesList,setDishesList] = useState([]);
    const [currentPage,setCurrentPage] = useState(1)
    const [pageSize,setPageSize] = useState(10)
    const [totalCount,setTotalCount] = useState(0);
    const [modalState,setModalState] = useState(false)
    const columns=[{name:"ID",key:"id"},{name: "Dish Name",key:"Menu_Name"},{name: "Dish Type",key:"Menu_Type"},{name:"Action",key:"action"}]
    useEffect(()=>{
        if(modalState === false)
        axiosInstance.get("menus/get_all_dishes",{
            params: {
                Limit: pageSize,
                Offset: ((currentPage-1)*pageSize),
                Menu_Name: ""
            }
        })
        .then((data)=>{
            if(data[0]?.total_count){
                const totalRows  = data[0].total_count;
                setDishesList(data);
                setTotalCount(totalRows)
            }else{
                Toaster("error",data)
            }
        })
        .catch((err)=>Toaster("error",err))
    },[currentPage,pageSize,modalState]);
    
    const [modalContent,setModalContent] = useState({});

  return (
    <Fragment>
        <div className="flex justify-between w-[80%] items-center mx-auto mt-7">
            <p className="text-2xl font-bold">Dishes</p>
            <StdButton className={"py-2"} onClick={()=>{setModalState(true); setModalContent({})}}>
                Add Dish
            </StdButton>
        </div>
        <ScrollableModal modalState={modalState} title={modalContent.Menu_Id ? "Edit Dish" :'ADD New Dish'} 
            content={<DishModal content={modalContent} setModalState={setModalState}/>} onClose={()=>setModalState(false)}/>
        <div className=" w-[80%] mx-auto my-7">
            <div className="rounded-2xl relative z-30 overflow-x-hidden max-h-[80vh] overflow-y-scroll shadow-md">
            <table className="rounded-2xl table-fixed relative min-w-full">
                <thead className="rounded-2xl">
                    <tr className="bg-[#FFC092] rounded-2xl sticky top-0">
                        {
                            columns.map((col)=>
                                <TableCellWithToolTip key={col.name} 
                            className="p-4 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]"
                            content={col.name} header/>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        dishesList.map((row,rowIndex)=>(
                            <tr key={row.Menu_Id} className={`p-2 ${rowIndex%2 === 0 && " bg-white "}`}>
                                    <td className="p-4 text-center truncate max-w-[150px]">
                                        { ( (currentPage-1) * pageSize) + rowIndex+1}
                                    </td>
                                {
                                    columns.slice(1,3).map((col)=>(
                                        <TableCellWithToolTip key={col.key} 
                                        className="p-4 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]"
                                        content={row[col.key]} />
                                    ))
                                }
                                    <td className='text-center'>
                                        <StdButton className="py-[0.5rem]" onClick={()=>{ setModalContent(row); setModalState(true); }}>
                                            Edit
                                        </StdButton>
                                        {/* <StdButton className="py-[0.5rem]">
                                            Delete
                                        </StdButton> */}
                                    </td>
                            </tr>
                        ))
                    }
                </tbody>
                <tfoot>
                    <tr className="bg-white border-t-[1px] border-gray-300 sticky bottom-0">
                    <td className='text-center p-4' colSpan={columns.length}>
                        <Pagination
                            currentPage={currentPage}
                            totalCount={totalCount}
                            pageSize={pageSize}
                            onPageSizeChange={setPageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </td>
                    </tr>
                </tfoot>
                </table>
            </div>
        </div>
    </Fragment>
  )
}

export default AdminDishes