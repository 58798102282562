import React,{Fragment, useCallback, useMemo, useRef, useState} from 'react'
import { useAuth } from '../contexts/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { LeftArrow, PasswdEye, RightArrow } from '@images/sidebarIcons';
import { SingleFileUpload } from '@components/UtilityComponents';
import ProfileAvatar from "@images/profile_avatar.png";
import { Toaster } from '@constants/appConstants';
import axiosInstance from 'apis/config';


const signUpInit = {
    cName:"",cEmail: "",cPhoneNumber:"",
    address:"",state:"",city:"",
    cNameError: false,cEmailError:false,cPhoneNumberError:false,
    addressError: false,stateError:false,cityError: false,
    uName:"",uEmail:"",password:"",uPhoneNumber:"",
    uNameError:false,uEmailError:false,passwordError: false,
    uPhoneNumberError:false
}

const statusInit = [{status:"active"},{status:""}]

const Signup = () => {

    const [signupForm,setSignupForm] = useState({...signUpInit});
    const {isAuthenticated} = useAuth();
    const [stepProgress,setStepProgress] = useState([...statusInit]);
    const [companyLogo,setCompanyLogo] = useState();
    const navigate = useNavigate();

    const companyPhotoRef=useRef();
    
    const isAllStepsCompleted=useCallback(()=>{
        return Object.values(stepProgress).every(({status})=>status==="success") ? "completed":""
    },[stepProgress]);

    const findActiveIndex=useMemo(()=> stepProgress.findIndex(({ status }) => status==="active"),[stepProgress]);


    if(isAuthenticated){
        return <Navigate to="/" replace />
    }

    const handleChange=(e)=>{
        const {name,value} = e.target;
        setSignupForm((prev)=>({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
    let signupFormCopy = {...signupForm}

    signupFormCopy.cNameError =  signupFormCopy.cName.trim().length <= 2;
    signupFormCopy.cEmailError = ! (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(signupFormCopy.cEmail));
    signupFormCopy.cPhoneNumberError = ! (/^\d{10}$/).test(signupFormCopy.cPhoneNumber);
    signupFormCopy.addressError =  signupFormCopy.address.trim().length <= 2;
    signupFormCopy.stateError = signupFormCopy.state.trim().length <= 2;
    signupFormCopy.cityError = signupFormCopy.city.trim().length <= 2;
    signupFormCopy.uNameError = signupFormCopy.uName.trim().length <= 2;
    signupFormCopy.uEmailError = ! (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(signupFormCopy.uEmail));
    signupFormCopy.passwordError = signupFormCopy.password.trim().length <= 5;
    signupFormCopy.uPhoneNumberError = ! (/^\d{10}$/).test(signupFormCopy.uPhoneNumber);
     
        if(
            ["cNameError","cEmailError","cphoneNumberError","addressError",
                "stateError","cityError","uNameError","uEmailError","passwordError",
            "uPhoneNumberError"]
                .some((elem)=>signupFormCopy[elem]===true)
        ){
            Toaster("error","Please fill all the fields properly");
            setSignupForm(signupFormCopy);
        }else{
            // if there are no errors
            let formData = new FormData();
            [
                "cName","cEmail","cPhoneNumber","address","state","city",
                "uName","uEmail","password","uPhoneNumber"
            ].forEach((field)=>{
                formData.append(field,signupFormCopy[field])
            });

            if(companyLogo){
                formData.append("Logo",companyLogo)
            }

            axiosInstance.post("user/signup",formData,{
                headers:{
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((response)=>{

                if(response.company_details && response.user_details){
                    Toaster("success","Registration Successful");
                    Toaster("success","Congrats you have been registered with Basic Plan!")
                    setTimeout(()=>navigate("/login"),3000);
                }
            })
            .catch((err)=>Toaster("error",err))
        }
    };

    const handleStepProgress = (type, status) => {
        let stepCopy = stepProgress.slice();
        let currentActiveKey = findActiveIndex;
    
        if (currentActiveKey > -1) {
            // Update the current active step's status
            stepCopy[currentActiveKey].status = status    
            // Determine the new active step index
            let newActiveKey = currentActiveKey;//NOSONAR
    
            // Adjust the active step based on the type
            switch (type) {
                case "next":
                    newActiveKey = currentActiveKey + 1; // Move to the next step
                    break;
                case "back":
                    newActiveKey = currentActiveKey - 1; // Move to the previous step
                    break;
                default:
                    newActiveKey = 0; // Default to the first step if an invalid type is provided
            }
    
            // Ensure the new active key is within bounds
            if (newActiveKey >= 0 && newActiveKey < stepCopy.length) {
                stepCopy[newActiveKey].status = "active"; // Set the new active step
            }
    
            // Update the state
            setStepProgress(stepCopy);
        }
    };

    const handleNext=(type,activeIndex)=>{
        let signupFormCopy = {...signupForm};
        let currentStatus = "success"

        if(activeIndex===1){
            //STEP 1
            signupFormCopy.cNameError =  signupFormCopy.cName.trim().length <= 2;
            signupFormCopy.cEmailError = ! (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(signupFormCopy.cEmail));
            signupFormCopy.cPhoneNumberError = ! (/^\d{10}$/).test(signupFormCopy.cPhoneNumber);
            signupFormCopy.addressError =  signupFormCopy.address.trim().length <= 2;
            signupFormCopy.stateError = signupFormCopy.state.trim().length <= 2;
            signupFormCopy.cityError = signupFormCopy.city.trim().length <= 2;
            if(["cNameError","cEmailError","cphoneNumberError","addressError","stateError","cityError"]
                .some((elem)=>signupFormCopy[elem] === true)
            ){
              
                currentStatus = "error"
            }
        }

        if(activeIndex===2){
            //STEP 2
            signupFormCopy.uNameError = signupFormCopy.uName.trim().length <= 2;
            signupFormCopy.uEmailError = ! (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(signupFormCopy.uEmail));
            signupFormCopy.passwordError = signupFormCopy.password.trim().length <= 5;
            signupFormCopy.uPhoneNumberError = ! (/^\d{10}$/).test(signupFormCopy.uPhoneNumber);

            if(["uNameError","uEmailError","passwordError","uPhonNumberError"]
                .some((elem)=>signupFormCopy[elem]===true)
            ){
               
              currentStatus = "error"
            }
        }
      
        if(currentStatus==="error"){
            Toaster("error","Please check all the fields are filled without error")
        }
        
        handleStepProgress(type,currentStatus);
        setSignupForm({...signupFormCopy});
    }

    return (
    <Fragment>
        <style>
            {
                `
                    #signup input,#signup textarea,#signup .hs-select {
                    border-radius: 20px;
                    padding: 14px;
                    font-size: 16px;
                    height: fit-content;
                    border: 1px solid #d3d3d3;
                    box-shadow: 0 0 5pt 0.5pt #D3D3D3;
                    outline: none;
                    margin: 0.5rem 1rem;
                  }

                    #signup .hs-select{
                        padding: 7px;
                        width: 100%;
                    }
                    
                    #signup input.input-error,#signup textarea.input-error{
                        border-color: #ef4444;
                    }
                    #signup input.input-error:focus,#signup textarea.input-error:focus{
                        border-color: #ef4444;
                      box-shadow: 0 0 0 1px #ef4444 !important;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                    }
                    #signup input[type=number] {
                    -moz-appearance: textfield;
                    }
                `
            }
        </style>
        <section id="signup" className='absolute top-[2%] left-[10%] w-[80%] overflow-clip'>
            <h1 className="text-[#D51037] text-4xl relative font-bold text-center">SIGN UP</h1>
            <hr className=' bg-gray-300 h-1px'/>
            
            <div id="error-stepper" className={isAllStepsCompleted() + " w-full "} data-hs-stepper={`{ "currentIndex": ${findActiveIndex+1} }`}>
            
                <ul className="relative flex flex-row gap-x-2 w-full mt-3">
                    
                    {
                        stepProgress.map(({status},stepIndex)=>{
                            return(
                                <li key={stepIndex+1} 
                                    className={`flex items-center gap-x-2 shrink basis-0 flex-1 group ${status}`}
                                    data-hs-stepper-nav-item={` {"index": ${stepIndex+1} }` }>
                                    <div className="w-full h-px flex-1 bg-gray-200 hs-stepper-success:bg-[#D51037] hs-stepper-completed:bg-teal-600"></div>
                                    <span className="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle focus:outline-none disabled:opacity-50 disabled:pointer-events-none">
                                        <span className="size-7 flex justify-center items-center shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 
                                        hs-stepper-active:bg-[#D51037] hs-stepper-success:bg-[#D51037] 
                                        hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600 
                                        hs-stepper-error:bg-red-500 
                                        hs-stepper-active:text-white hs-stepper-success:text-white 
                                        hs-stepper-processed:bg-white hs-stepper-processed:border hs-stepper-processed:border-gray-200">
                                        <span className="text-base font-semibold hs-stepper-success:hidden hs-stepper-completed:hidden hs-stepper-error:hidden hs-stepper-processed:hidden">
                                            {stepIndex+1}
                                        </span>
                                        <svg className="hidden shrink-0 size-3 hs-stepper-success:block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
                                            <polyline points="20 6 9 17 4 12"></polyline>
                                        </svg>
                                        <svg className="hidden shrink-0 size-3 hs-stepper-error:block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M18 6 6 18"></path>
                                            <path d="m6 6 12 12"></path>
                                        </svg>
                                        <span className="hidden animate-spin size-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full hs-stepper-processed:inline-block">
                                            <span className="sr-only">Loading...</span>
                                        </span>
                                        </span>
                                        <span className="ms-2 text-lg font-medium text-gray-800 group-focus:text-gray-500">
                                        Step
                                        </span>
                                    </span>
                                    </li>
                            )
                        })
                    }
                    
                </ul>

                <div className="mt-5 sm:mt-8">
                    
                    <div data-hs-stepper-content-item='{
                    "index": 1
                    }' >
                        <div className="p-4  bg-gray-50 flex justify-center items-center border border-dashed border-gray-200 rounded-xl">
                            <form className="relative grid grid-flow-row w-full grid-cols-2">
                            <p className="col-span-2 text-center text-xl font-semibold">Company Profile</p>
                                <div className="grid grid-flow-row grid-cols-2 grid-rows-2 max-lg:grid-cols-1 col-span-2 items-center">
                                    <div className='mx-4 ps-3'>
                                        <SingleFileUpload image={companyLogo || ProfileAvatar} imageClass="rounded-md m-3  h-40" 
                                            fileInputRef={companyPhotoRef} handleChange={setCompanyLogo} handleReset={()=>setCompanyLogo()}/>
                                    </div>
                                    <div className='mx-4'>
                                        <input type="text" placeholder="Company Name" name="cName" 
                                            className={`w-full ${signupForm.cNameError && "input-error"} `} value={signupForm.cName} onChange={handleChange}/>
                                        
                                        {signupForm.cNameError && <p className='mx-4 text-red-600 font-medium'>Company Name should be atleast 3 letters</p> }
                                    </div>
                                    <div className='mx-4'>
                                        <input type="mail" placeholder="Company Email" name="cEmail" 
                                            className={`w-full ${signupForm.cEmailError && "input-error"} `} value={signupForm.cEmail} onChange={handleChange} />
                                        {signupForm.cEmailError && <p className='mx-4 text-red-600 font-medium'>Please Enter a valid Email</p> }
                                    </div>
                                    <div className='mx-4'>
                                        <input type="number" placeholder="Company Phone Number" name="cPhoneNumber" onChange={handleChange}
                                            className={`w-full ${signupForm.cPhoneNumberError && "input-error"} `} value={signupForm.cPhoneNumber}
                                        onWheel={(e)=>e.target.blur() && e.stopPropagation() && setTimeout(()=>e.currentTarget.focus(),0)}/>
                                        {signupForm.cPhoneNumberError && <p className='mx-4 text-red-600 font-medium'>Please Enter a valid Phone Number</p> }
                                    </div>
                                </div>
                                <div className='mx-4 col-span-2  grid grid-flow-col grid-cols-2'>
                                    <div>
                                        <textarea
                                            placeholder="Address"
                                            rows="6" name="address" value={signupForm.address}
                                            className={`w-full col-span-1 resize-none ${signupForm.addressError && "input-error"}`}
                                            onChange={handleChange}
                                            />
                                        {signupForm.addressError && <p className='mx-4 text-red-600 font-medium'>Please Enter a valid Address</p> }
                                    </div>
                                <div className="grid grid-flow-col grid-cols-1 items-center grid-rows-2 max-lg:grid-cols-1 col-span-1 w-full">
                                    <div className="mx-4">
                                        <input type="text" placeholder="State" name="state" 
                                            className={`w-full ${signupForm.stateError && "input-error"} `} value={signupForm.state} onChange={handleChange}/>
                                        {signupForm.stateError && <p className='mx-4 text-red-600 font-medium'>Please Enter a valid State</p> }
                                    </div>
                                    <div className="mx-4">
                                        <input type="text" placeholder="City/Town" name="city" 
                                            className={`w-full ${signupForm.cityError && "input-error"} `} value={signupForm.city} onChange={handleChange}/>
                                        {signupForm.cityError && <p className='mx-4 text-red-600 font-medium'>Please Enter a valid City</p> }
                                    </div>
                                </div>
                                </div>
                            </form>
                    
                        </div>
                    </div>
                    

                    
                    <div data-hs-stepper-content-item='{
                    "index": 2
                    }'  >
                        <div className="p-4 m-7 bg-gray-50 flex flex-col justify-center items-center border border-dashed border-gray-200 rounded-xl">
                            
                            <div className='mx-4'>
                                <input type="text" placeholder="Your Name" name="uName" 
                                    className={`w-full ${signupForm.uNameError && "input-error"} `} value={signupForm.uName} onChange={handleChange}/>
                                
                                {signupForm.uNameError && <p className='mx-4 text-red-600 font-medium'>User Name should be atleast 3 letters</p> }
                            </div>

                            <div className='mx-4'>
                                <input type="number" placeholder="Your Phone Number" name="uPhoneNumber" onChange={handleChange}
                                    className={`w-full ${signupForm.uPhoneNumberError && "input-error"} `} value={signupForm.uPhoneNumber}
                                onWheel={(e)=>e.target.blur() && e.stopPropagation() && setTimeout(()=>e.currentTarget.focus(),0)}/>
                                {signupForm.uPhoneNumberError && <p className='mx-4 text-red-600 font-medium'>Please Enter a valid Phone Number</p> }
                            </div>

                            <div className='mx-4'>
                                <input type="mail" placeholder="Your Email" name="uEmail" 
                                    className={`w-full ${signupForm.uEmailError && "input-error"} `} value={signupForm.uEmail} onChange={handleChange} />
                                {signupForm.uEmailError && <p className='mx-4 text-red-600 font-medium'>Please Enter a valid Email</p> }
                            </div>
                            
                            <div className="relative  mx-4">
                                <input id="hs-toggle-password" type="password" name="password" onChange={handleChange}
                                className={`py-4 ps-4 pe-10 block w-full border-[2px] rounded-lg ${signupForm.passwordError && " input-error "} `}
                                placeholder="Your password" value={signupForm.password}/>
                                <button type="button" data-hs-toggle-password='{
                                    "target": "#hs-toggle-password"
                                }' className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-[#FFC092]">
                                <PasswdEye className="shrink-0 size-3.5"/>
                                </button>
                            </div>
                            {  signupForm.passwordError && <p className=" mx-4 text-red-600 mt-2 font-medium" id="password-helper">Password should be atleast 6 letters</p>}
                        </div>
                    </div>
                    
                    <div data-hs-stepper-content-item='{
                    "isFinal": true
                    }' style={{display: "none"}}
                    className={`${isAllStepsCompleted() ? " block " : " hidden "}`}
                    >
                        <div className="p-4 h-48 bg-gray-50 flex justify-center items-center border border-dashed border-gray-200 rounded-xl">
                            <h3 className="text-gray-500">
                            Final content
                            </h3>
                        </div>
                    </div>
                    

                    <div className="mt-5 flex justify-between items-center gap-x-2">
                        <button type="button" onClick={()=>{handleStepProgress("back","success");}}
                            className="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm 
                            hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none" 
                            data-hs-stepper-back-btn="">
                            <LeftArrow />
                            Back
                        </button>
                        <button onClick={()=> { handleNext("next",findActiveIndex+1) }}
                            type="button" 
                            className="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-transparent 
                            bg-[#D51037] text-white  disabled:opacity-50 disabled:pointer-events-none" 
                            data-hs-stepper-next-btn="">
                            Next
                            <RightArrow />
                        </button>
                       
                        <button onClick={handleSubmit} 
                        className={`bg-[#D51037] text-white p-3 lg:w-[15%] my-2 mx-auto rounded-lg text-lg ${ stepProgress.slice(0,1).every((el)=>el.status==="success") ? "block" : "hidden"}`}>
                            SIGN UP
                        </button>
                        
                    </div>
                </div>
            
            </div>

          
        </section>
    </Fragment>
  )
}

export default Signup