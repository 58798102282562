import React,{Fragment} from 'react'
import ICE_group from "@images/ICE_group.png";
import AMT_group from "@images/AMT_group.png";
import TMSC_group from "@images/TMSC_group.png";
import AVM_group from "@images/AVM_group.png";


const FEATURES_CONTENT=[
    {img: ICE_group, main:"Interactive Client Engagement",
        sub: "Our intelligent bot interacts with clients, gathering event details, recommending personalized menus, and answering queries in real-time."},
    {img: AMT_group,main: "Advanced Management Tools",
        sub: "Enjoy precise ingredient calculations, vendor coordination, and lead generation, all powered by AI."
    },
    {
        img: TMSC_group, main: "Tailored Menus & Seamless Communication",
            sub: "Catermate ensures exceptional service and smooth operations for every event, making your catering business thrive."
    },
    {
        img: AVM_group, main: "Automated Vendor Management",
            sub: "Our intelligent bot interacts with vendors, sharing event details, organizing personalized menus, and answering queries in real-time."
    }
]

const Features = () => {
  return (
    <Fragment>
        <section id="features" className="relative ">
            <div className="text-[#D51037] font-bold text-4xl">Features</div>
            <div className="font-medium text-[1.2rem]">Take a look at our groundbreaking features</div>
            <div className=" mx-[8%] grid grid-flow-col gap-x-4 max-2xl:grid-flow-row 2xl:grid-cols-4 md:grid-cols-2 max-md:grid-cols-1 my-10 items-stretch max-xsm:w-[125%] w-[-webkit-fill-available]">
                {
                    FEATURES_CONTENT.map((card)=>(
                        <div className=" bg-white rounded-3xl p-5 text-center max-w-[18rem] max-h-[440px] my-10 mx-auto" key={card.main}>
                            <img src={card.img} alt={card.main} className="border-[10px] border-[#FEF1E1] p-0 rounded-full relative bottom-[25%] left-[25%]"/>
                            <div className="relative bottom-[20%]">
                                <p className="text-xl font-bold">{card.main}</p>
                                <p className="text-lg">{card.sub}</p>
                            </div>
                        </div>
                    ))
                }
               

            </div>
        </section>
    </Fragment>
  )
}

export default Features