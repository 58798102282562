import React, { Fragment,useState,useRef,useEffect } from 'react'
import { RightArrow,LeftArrow,DoubleRight,DownArrow,TickMark } from "@images/sidebarIcons";


const TableCellWithToolTip=({content,header,className,...props})=>{
    const cellRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);
  
    // Check if the text is truncated
    useEffect(() => {
      const cell = cellRef.current;
      if (cell) {
        setIsTruncated(cell.scrollWidth > cell.clientWidth);
      }
    }, [content]);
    
    const combinedRef=(element)=>{
        cellRef.current = element;
        if(props.ref)
            props.ref.current = element;
    }
    
    const element = header ? 'th' : 'td';

  
    return React.createElement(element,{ref:combinedRef,className:className+ " group ",...props},
        <Fragment>
        <span>{content}</span>
            {isTruncated && (
              <div 
              className="relative hidden z-10 left-0 text-wrap bottom-full mb-2 px-2 py-1 bg-black text-white 
                        text-xs rounded shadow-lg w-max max-w-[120px] group-hover:block">
                {content}
              </div>
            )}    
        </Fragment>  
    )
    
}

const ChatBubbble=({message,isSender})=>{

    return(
        <div className={`flex ${isSender ? 'justify-end' : 'justify-start'} mb-2`}>
            <div className={`max-w-xs p-3 rounded-lg shadow  bg-[#FFC092] text-gray-950 font-normal relative`}>
                <span>{message.content}</span>
                {/* Message tail bubble */}
                <div className={`absolute ${isSender ? '-right-[6px]' : '-left-[6px]'} top-1/2 transform translate-y-4 w-3 h-3 bg-[#FFC092] rotate-45`}></div>
                {/* Timestamp below the message */}
                <div className={`text-[10px] text-gray-800 font-light mt-1 ${ isSender ? "text-right" : "text-left" } "`}>{message.time}</div>
            </div>
        </div> 
    )
};

const AdminChatHistory = () => {
    const [chatList,setChatList] = useState([
        {orderId: 1,name: "Name 1 ",fnName: "fn 1",stDate: "2020-01-01",endDate: "2020-01-03",venue: "Venue 1",clNo: "+911234567890" },
        {orderId: 2,name: "Name 2",fnName: "fn 2",stDate: "2020-02-02",endDate: "2020-02-04",venue: "Venue 2",clNo: "+911234567890" },
        {orderId: 3,name: "Name 3",fnName: "fn 3",stDate: "2020-03-03",endDate: "2020-03-05",venue: "Venue 3",clNo: "+911234567890" },
        {orderId: 4,name: "Name 4",fnName: "fn 4",stDate: "2020-04-04",endDate: "2020-04-06",venue: "Venue 4",clNo: "+911234567890" },
        {orderId: 5,name: "Name 5",fnName: "fn 5",stDate: "2020-05-05",endDate: "2020-05-07",venue: "Venue 5",clNo: "+911234567890" },
        {orderId: 6,name: "Name 6",fnName: "fn 6",stDate: "2020-06-06",endDate: "2020-06-08",venue: "Venue 6",clNo: "+911234567890" }
    ]);
    const columns=[{name: "Order Id",key: "orderId"},{name: "Name",key:"name"},{name: "Client Number",key:"clNo"},{name: "Action"}]

  return (
    <Fragment>
        <div className="flex justify-between w-[90%] items-center mr-auto mt-7">
            <p className="text-2xl font-bold place-content-start">Chat History</p>
        </div>
        <div className="grid grid-flow-row grid-cols-2 w-[90%] mr-auto">
            
            <div id="chat-list">
                {/*switching tabs*/}
                <div className="border-b border-gray-200">
                    <nav className="flex gap-x-1"  aria-label="Tabs" role="tablist" aria-orientation="horizontal"> {/*NOSONAR*/}
                        <button type="button" 
                            className="hs-tab-active:font-semibold text-xl hs-tab-active:border-[#FF6309] hs-tab-active:text-[#FF6309] py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent  whitespace-nowrap text-gray-500 hover:text-[#FF6309] focus:outline-none disabled:opacity-50 disabled:pointer-events-none active" 
                            id="tabs-with-underline-item-1" aria-selected="true" data-hs-tab="#tabs-with-underline-1" aria-controls="tabs-with-underline-1" role="tab">
                        User Chats
                        </button>
                        <button type="button" 
                            className="hs-tab-active:font-semibold text-xl hs-tab-active:border-[#FF6309] hs-tab-active:text-[#FF6309] py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent  whitespace-nowrap text-gray-500 hover:text-[#FF6309] focus:outline-none  disabled:opacity-50 disabled:pointer-events-none" 
                            id="tabs-with-underline-item-2" aria-selected="false" data-hs-tab="#tabs-with-underline-2" aria-controls="tabs-with-underline-2" role="tab">
                        Admin Chats
                        </button>
                    </nav>
                </div>
                {/*tab content*/}
                <div className="mt-3 container">
                    <div id="tabs-with-underline-1" role="tabpanel" aria-labelledby="tabs-with-underline-item-1">
                        <div className="rounded-2xl overflow-hidden min-w-fit shadow-md">
                            <table className=" table-fixed min-w-full">
                                <thead className="rounded-2xl">
                                    <tr className="bg-[#FFC092] rounded-2xl">
                                        {
                                            columns.map((col)=>(
                                                <TableCellWithToolTip key={col.name} 
                                                className="p-4 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]"
                                                content={col.name} header/>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        chatList.map((row,rowIndex)=>(
                                            <tr key={row.orderId} className={`p-2 ${rowIndex%2 === 0 && " bg-white "}`}>
                                                {
                                                    columns.map((col)=>(
                                                        col.key ?
                                                        <TableCellWithToolTip content={row[col.key]} 
                                                            className="p-2 text-center table-cell truncate whitespace-nowrap overflow-hidden max-w-[150px]"
                                                        key={col.key}/>
                                                        : null
                                                    ))
                                                }
                                                <td className="p-2 text-center"><button className="rounded-3xl bg-[#ffc092] px-4 py-2">View</button></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr className="bg-white border-t-[1px] border-gray-300">
                                    <td className="text-center p-4" colSpan={columns.length > 4 ? columns.length -  3 : 2}>
                                
                                        <nav className="flex justify-end items-center -space-x-px border-[1px] w-fit rounded-full  border-gray-400" aria-label="Pagination">
                                            <button type="button" className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-1.5 text-sm first:rounded-s-full last:rounded-e-full border border-gray-200 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none" aria-label="Previous">
                                            
                                            <LeftArrow className="shrink-0 size-4"/>
                                            <span className="sr-only">Previous</span>
                                            </button>
                                            <button type="button" className="min-h-[38px] min-w-[38px] flex justify-center items-center bg-[rgb(255,202,138)] text-gray-800 border border-gray-200 py-2 px-3 text-sm first:rounded-s-full last:rounded-e-full focus:outline-none focus:bg-gray-300 disabled:opacity-50 disabled:pointer-events-none" aria-current="page">1</button>
                                            <button type="button" className="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm first:rounded-s-full last:rounded-e-full focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">2</button>
                                            <button type="button" className="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm first:rounded-s-full last:rounded-e-full focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">3</button>
                                            <div className="hs-tooltip inline-block border border-gray-200">
                                            <button type="button" className="hs-tooltip-toggle group min-h-[36px] min-w-[36px] flex justify-center items-center text-gray-400 hover:text-blue-600 p-2 text-sm focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                                                <span className="group-hover:hidden text-xs text-black">•••</span>
                                                <DoubleRight className="group-hover:block hidden shrink-0 size-5 text-gray-800"/>
                                                <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm hidden" role="tooltip">
                                                Next 4 pages
                                                </span>
                                            </button>
                                            </div>
                                            <button type="button" className="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm first:rounded-s-full last:rounded-e-full focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">8</button>
                                            <button type="button" className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-1.5 text-sm first:rounded-s-full last:rounded-e-full border border-gray-200 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none" aria-label="Next">
                                            <RightArrow className="shrink-0 size-4"/>
                                            <span className="sr-only">Next</span>
                                            </button>
                                        </nav>
                                    </td>

                                            <td className="text-center p-4" >
                                            <div className="hs-dropdown [--placement:bottom-left] relative inline-flex">
                                            <button id="hs-default-pilled-bordered-group-pagination-dropdown" type="button" className="hs-dropdown-toggle py-2 px-2.5 inline-flex items-center gap-x-1 text-sm rounded-lg border border-gray-200 text-gray-800 shadow-sm bg-white focus:outline-non disabled:opacity-50 disabled:pointer-events-none" aria-haspopup="menu" aria-expanded="false" aria-label="Dropdown">
                                                5 Rows per page
                                                <DownArrow className="shrink-0 size-4"/>
                                            </button>
                                            <div className="hs-dropdown-menu hs-dropdown-open:opacity-100 w-18 hidden z-50 transition-[margin,opacity] opacity-0 duration-300 mb-2 bg-white shadow-md rounded-lg p-1 space-y-0.5" role="menu" aria-orientation="vertical" aria-labelledby="hs-default-pilled-bordered-group-pagination-dropdown">
                                                <button type="button" className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                                                5
                                                <TickMark className="ms-auto shrink-0 size-4 text-gray-700"/>
                                                </button>
                                                <button type="button" className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                                                8
                                                </button>
                                                <button type="button" className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                                                10
                                                </button>
                                            </div>
                                            </div>
                                            </td>

                                            <td className="text-center p-4">
                                                <div className="flex items-center justify-center gap-x-2">
                                                <span className="text-sm text-gray-800 whitespace-nowrap">
                                                    Go to
                                                </span>
                                                <input type="number" 
                                                    className="min-h-[38px] py-2 px-2.5 block w-12 outline-none border-[2px] border-gray-200 rounded-lg text-sm text-center
                                                        [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none disabled:opacity-50 disabled:pointer-events-none" 
                                                    style={{"MozAppearance": "textfield"}} />
                                                <span className="text-sm text-gray-800 whitespace-nowrap">
                                                    page
                                                </span>
                                                </div>
                                            </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div id="tabs-with-underline-2" className="hidden" role="tabpanel" aria-labelledby="tabs-with-underline-item-2">
                        <div className="rounded-2xl overflow-hidden min-w-fit shadow-md">
                            <table className=" table-fixed min-w-full">
                                <thead className="rounded-2xl">
                                    <tr className="bg-[#FFC092] rounded-2xl">
                                        {
                                            columns.map((col)=>(
                                                <TableCellWithToolTip key={col.name} 
                                                className="p-4 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]"
                                                content={col.name} header/>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        chatList.map((row,rowIndex)=>(
                                            <tr key={row.orderId} className={`p-2 ${rowIndex%2 === 0 && " bg-white "}`}>
                                                {
                                                    columns.map((col)=>(
                                                        col.key ?
                                                        <TableCellWithToolTip content={row[col.key]} 
                                                            className="p-2 text-center truncate whitespace-nowrap overflow-hidden max-w-[150px]"
                                                        key={col.key}/>
                                                        : null
                                                    ))
                                                }
                                                <td className="p-2 text-center"><button className="rounded-3xl bg-[#ffc092] px-4 py-2">View</button></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr className="bg-white border-t-[1px] border-gray-300">
                                    <td className="text-center p-4" colSpan={columns.length > 4 ? columns.length -  3 : 2}>
                                
                                        <nav className="flex justify-end items-center -space-x-px border-[1px] w-fit rounded-full  border-gray-400" aria-label="Pagination">
                                            <button type="button" className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-1.5 text-sm first:rounded-s-full last:rounded-e-full border border-gray-200 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none" aria-label="Previous">
                                            
                                            <LeftArrow className="shrink-0 size-4"/>
                                            <span className="sr-only">Previous</span>
                                            </button>
                                            <button type="button" className="min-h-[38px] min-w-[38px] flex justify-center items-center bg-[rgb(255,202,138)] text-gray-800 border border-gray-200 py-2 px-3 text-sm first:rounded-s-full last:rounded-e-full focus:outline-none focus:bg-gray-300 disabled:opacity-50 disabled:pointer-events-none" aria-current="page">1</button>
                                            <button type="button" className="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm first:rounded-s-full last:rounded-e-full focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">2</button>
                                            <button type="button" className="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm first:rounded-s-full last:rounded-e-full focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">3</button>
                                            <div className="hs-tooltip inline-block border border-gray-200">
                                            <button type="button" className="hs-tooltip-toggle group min-h-[36px] min-w-[36px] flex justify-center items-center text-gray-400 hover:text-blue-600 p-2 text-sm focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                                                <span className="group-hover:hidden text-xs text-black">•••</span>
                                                <DoubleRight className="group-hover:block hidden shrink-0 size-5 text-gray-800"/>
                                                <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm hidden" role="tooltip">
                                                Next 4 pages
                                                </span>
                                            </button>
                                            </div>
                                            <button type="button" className="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm first:rounded-s-full last:rounded-e-full focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">8</button>
                                            <button type="button" className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-1.5 text-sm first:rounded-s-full last:rounded-e-full border border-gray-200 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none" aria-label="Next">
                                            <RightArrow className="shrink-0 size-4"/>
                                            <span className="sr-only">Next</span>
                                            </button>
                                        </nav>
                                    </td>

                                            <td className="text-center p-4" >
                                            <div className="hs-dropdown [--placement:bottom-left] relative inline-flex">
                                            <button id="hs-default-pilled-bordered-group-pagination-dropdown" type="button" className="hs-dropdown-toggle py-2 px-2.5 inline-flex items-center gap-x-1 text-sm rounded-lg border border-gray-200 text-gray-800 shadow-sm bg-white focus:outline-non disabled:opacity-50 disabled:pointer-events-none" aria-haspopup="menu" aria-expanded="false" aria-label="Dropdown">
                                                5 Rows per page
                                                <DownArrow className="shrink-0 size-4"/>
                                            </button>
                                            <div className="hs-dropdown-menu hs-dropdown-open:opacity-100 w-18 hidden z-50 transition-[margin,opacity] opacity-0 duration-300 mb-2 bg-white shadow-md rounded-lg p-1 space-y-0.5" role="menu" aria-orientation="vertical" aria-labelledby="hs-default-pilled-bordered-group-pagination-dropdown">
                                                <button type="button" className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                                                5
                                                <TickMark className="ms-auto shrink-0 size-4 text-gray-700"/>
                                                </button>
                                                <button type="button" className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                                                8
                                                </button>
                                                <button type="button" className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                                                10
                                                </button>
                                            </div>
                                            </div>
                                            </td>

                                            <td className="text-center p-4">
                                                <div className="flex items-center justify-center gap-x-2">
                                                <span className="text-sm text-gray-800 whitespace-nowrap">
                                                    Go to
                                                </span>
                                                <input type="number" 
                                                    className="min-h-[38px] py-2 px-2.5 block w-12 outline-none border-[2px] border-gray-200 rounded-lg text-sm text-center
                                                        [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none disabled:opacity-50 disabled:pointer-events-none" 
                                                    style={{"MozAppearance": "textfield"}} />
                                                <span className="text-sm text-gray-800 whitespace-nowrap">
                                                    page
                                                </span>
                                                </div>
                                            </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div id="selected-chat-history" className="ps-8 ml-5">
                 <div className="text-xl font-semibold">Chat History</div>
                 <div className="container">
                    <div className='container text-xl header font-semibold rounded-t-xl bg-[#FFC092] p-4'>
                        <span>User Name - Phone Number</span>
                    </div>
                    <div className="min-h-[70vh] max-h-[70vh] bg-white overflow-y-scroll px-4 py-8">
                        <ChatBubbble message={{content: "Hi",time: "11:20AM"}} isSender/>
                        <ChatBubbble message={{content: "Welcome to XYZ Catering Testing with a long text to handle chat",time: "11:21AM"}} />
                    </div>
                </div>                   
            </div>
        </div>
    </Fragment>
  )
}

export default AdminChatHistory