import React,{useState,useEffect,useRef,Fragment} from 'react'


export const TableCaption=({children,...atts})=>{
    return <caption {...atts}>{children}</caption>
};

export const TableRow=({children,header,visibleColumns=[],cell,...atts})=>{
    const columns = header ? Array(children.length).fill("name") : visibleColumns;
    return (
            <tr {...atts}>
                {
                    columns.length > 0  ?
                    columns.map((col)=>{
                        return <TableCell header={header} key={col}>{ children }</TableCell>
                    })
                    :
                    {children}
                }
            </tr>

    )
};

export const TableCell=({header,children,renderCell,...atts})=>{

    const element = header ? 'th' : 'td';
    const cellData = renderCell ? renderCell(children) : children;
    
    return React.createElement(element,atts,cellData)
};

export const TableHead=({children,rowAtts,...atts})=>{
        
    return(
        <thead {...atts}>
            { //if it is a multi row tableheader
            Array.isArray(children[0]) ? 
                children.map(
                    ({children: colRow,...rowAtts})=>(
                         <TableRow {...rowAtts} header key={colRow}>
                            {colRow}
                         </TableRow>
                       )
                )
            :   
                <TableRow {...rowAtts}  header>
                    {children}
                </TableRow>
            }
        </thead>
    )
};

const Table = ({
    caption,
    tableHead
}) => {
  return (
    <table>
        <TableCaption {...caption}/>
        <TableHead {...tableHead}/>
    </table>
  )
}

export const TableCellWithToolTip=({content,header,className,...props})=>{
    const cellRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);
  
    // Check if the text is truncated
    useEffect(() => {
      const cell = cellRef.current;
      if (cell) {
        setIsTruncated(cell.scrollWidth > cell.clientWidth);
      }
    }, [content]);
    
    const combinedRef=(element)=>{
        cellRef.current = element;
        if(props.ref)
            props.ref.current = element;
    }
    
    const element = header ? 'th' : 'td';

  
    return React.createElement(element,{ref:combinedRef,className:className+ " group ",...props},
        <Fragment>
        <span>{content}</span>
            {isTruncated && (
              <div 
              className="relative hidden z-10 left-0 text-wrap bottom-full mb-2 px-2 py-1 bg-black text-white 
                        text-xs rounded shadow-lg w-max max-w-[120px] group-hover:block">
                {content}
              </div>
            )}    
        </Fragment>  
    )
    
}
export default Table