import React, { Fragment,useState,useRef,useCallback,useReducer,useEffect } from 'react'
import { Loader, ScrollableModal, SingleFileUpload, StdButton } from '@components/UtilityComponents';
import { CancelSub,PlusIcon,UpCollapse,DownCollapse } from '@images/sidebarIcons';
import MenuTileCover from "@images/DishPlaceholder.jpg";
import axiosInstance, { baseURL } from 'apis/config';
import { ToolTip } from '@components/UtilityComponents';
import { isValidUrl, Toaster } from '@constants/appConstants';

const Ingredients=({ingredients,ingdClass,refetchIngd})=>{
  const [deletedIngs,setDeletedIngs] = useState([]);

  const handleIngredients = (state,action)=>{ 
      switch (action.type) {
        case "add":
              return [...state,action.data];

        case "delete":
              let deleted = state.filter((_,index)=>index === action.deleteIndex)
              setDeletedIngs((prev)=>[...prev,...deleted])
              return state.filter((elem,index)=>index!==action.deleteIndex);

        default:
            return ingredients;
      }
  };
  const [addOrEditState,setAddOrEditState] = useState(false);
  const [currentIngredients,setCurrentIngredients] = useReducer(handleIngredients,ingredients);
  const [newIngredient,setNewIngredient] = useState("");

  const handleNewIngredient=()=>{
    if(newIngredient.trim()){
      setCurrentIngredients({type : "add", 
        data: { Ingredient_Name:newIngredient.trim(),Ingredient_id:null }
      }); 
      setNewIngredient("")
    }
  };

  const handleSaveIngredients=()=>{
    if(addOrEditState){
      if(currentIngredients.length > 0){
        let newIngs = currentIngredients.filter((el)=>el.Ingredient_id ==null);
        
        let jsonBody = {
          newIngs : newIngs.map((it)=>it.Ingredient_Name).join(","),
          deletedIngs: deletedIngs.map((it)=>it.Ingredient_id).join(","),
          vendor_id: ingdClass.Vendor_Id
        }
        
       axiosInstance.put("menus/ingredients-list",jsonBody)
                    .then((response)=>{
                      if(response.current_ingds){
                        refetchIngd()
                        setAddOrEditState(false)
                        Toaster("success","Successfully updated Ingredients")
                      }
                    })
                    .catch(console.error)
      }else{
        Toaster("error","There Should be atleast one ingredient")
      }
    }else{
      setAddOrEditState(!addOrEditState)
    }
  }

  return(
    <div className="flex flex-col justify-between my-4">
        <div>
          <button className="underline mx-2 text-[1rem]" onClick={()=>handleSaveIngredients()}>
            {addOrEditState ? "Save the Items" : "Add or Edit Items"}
          </button>
          <button className={`mx-2 underline text-[1rem] ${addOrEditState ? "inline": "hidden"}`} 
            onClick={()=>{setAddOrEditState(false); setCurrentIngredients({type:"default"});}}>
            Cancel Editing
          </button>
        </div>
        <div className={`max-w-sm space-y-3 ${addOrEditState ? "block" : "hidden"} `}>
          
            <div className="relative">
              <input type="text" id="hs-trailing-icon" name="hs-trailing-icon" placeholder="Add Item"
                    className="py-3 px-4 pe-11 block w-full border-[1px] border-gray-600 shadow-2xl rounded-full 
                        outline-none focus:z-10 focus:border-gray-600 focus:ring-gray-600" 
                    value={newIngredient} onChange={(e)=>setNewIngredient(e.target.value)}
                  />
              <div className="absolute p-2 shadow-md shadow-gray-500   border-[1px] border-gray-600 rounded-md inset-y-0 end-0
                              flex items-center cursor-pointer z-20 m-2" 
                              onClick={()=>{ handleNewIngredient();  }}> 
                Add <PlusIcon className="rounded-md size-4 " />
              </div>
            </div>
          
        </div>
        <div className="grid grid-flow-row grid-cols-4 my-4">
            {
              currentIngredients?.map((ingrd,ingdIndex)=>{
                return(
                  <span key={ingrd.Ingredient_Name} className=" rounded-full bg-[#FFF1DB] p-3 m-1 relative">
                    <span className=" text-center inline">
                    {ingrd.Ingredient_Name}
                  </span>
                  {addOrEditState && 
                  <CancelSub className="absolute inline right-0 cursor-pointer" 
                      onClick={()=>setCurrentIngredients({type: "delete",deleteIndex: ingdIndex})} />}
                  </span>
                )
              })
            }
        </div>
        
     </div>
  )
}

const IngredientModal=({content,onSave})=>{
  const [vendorType,setVendorType] = useState(content.Vendor_type || "");
  const photoRef = useRef();
  const [ingPhoto,setIngPhoto] = useState(content.image_path);
  
  const handleSave=()=>{
    const HTTP_METHOD = content.Vendor_Id ? "put" : "post";
    const formData = new FormData();
    if(content.Vendor_Id)
    formData.append("vendor_Id",content.Vendor_Id);
    formData.append("vendor_type",vendorType);
    // if it is  a valid http or has a valid value in photo
    if(ingPhoto){
      let image = isValidUrl(ingPhoto) ? ingPhoto.replace(baseURL,"") : ingPhoto
      formData.append("image_path",image)
    }
    // if new image is not same as old image and it is valid value,(not undefined or not null)
    if(content.image_path !== ingPhoto && content.image_path){
      formData.append("oldImage",content.image_path)
    }
    axiosInstance[HTTP_METHOD]("menus/ingredient-class",formData,{
      headers:{
        'Content-Type': "multipart/form-data"
      }
    })
    .then((resp)=> {
      if(resp.Vendor_Id)
      onSave(resp)
    else
      Toaster("error",resp) 
    }
  )
    .catch((err)=>Toaster("error",err))
  }

  return(
      <div className='flex flex-col p-6 m-3'>
          <SingleFileUpload image={ingPhoto || MenuTileCover} 
                                    fileInputRef={photoRef} 
                                    handleChange={setIngPhoto} handleReset={()=>setIngPhoto(content.image_path)}
                                    imageClass={"shrink w-ful h-40 rounded-2xl"}
                                    />
            <input value={vendorType} onChange={(e)=>setVendorType(e.target.value)} 
                  placeholder="Subcategory name" autoFocus={true}
                  className={`py-3 px-4 my-5 mx-auto  inline rounded-full outline-none w-[50%]
                            shadow-inner border-[1px] focus:border-gray-600 focus:ring-gray-600 border-gray-600`}
                />
        <button type="button" onClick={handleSave}
            className="py-2 px-3 w-fit mx-auto my-4 inline-flex items-center gap-x-2 text-[1rem] font-semibold rounded-lg 
            border border-gray-400 bg-[#FFC092] text-gray-800" 
            >
            Save changes
        </button>
      </div>
  )
}

const AdminIngredients = () => {

  const [ingredientClasses,setIngredientClasses] = useState([]);

 const accordionElementRefs = useRef([]);

 const addIngredients=(ingrdntClassesCopy,index)=>{
  let CURRENT_INGREDIENT = ingrdntClassesCopy[index];

  axiosInstance.get("menus/ingredients-by-type/"+CURRENT_INGREDIENT.Vendor_Id)
                .then((resp)=>{
                  CURRENT_INGREDIENT.ingredients = resp;
                  ingrdntClassesCopy[index] = CURRENT_INGREDIENT;
                  setIngredientClasses(ingrdntClassesCopy);
                })
                .catch((err)=>Toaster("error",err))
};

 const updateCategoryDetail=useCallback((index)=>{
    let ingdntClassesCopy = ingredientClasses.slice();
    const toggleState = !ingredientClasses[index].open;
    //reset toggle
    ingdntClassesCopy = ingdntClassesCopy.map((accItem)=>{accItem.open=false;return accItem});
    ingdntClassesCopy[index].open = toggleState;
    if(ingdntClassesCopy[index].ingredients){
      setIngredientClasses(ingdntClassesCopy)
    }else{
      addIngredients(ingdntClassesCopy,index);
    }
  },[ingredientClasses]);

 const handleOpenState=useCallback((e,index)=>{
  e.stopPropagation();
  e.preventDefault();
  updateCategoryDetail(index) 

},[updateCategoryDetail]);

 useEffect(()=>{
    const cleanUps = accordionElementRefs.current.map((element,index)=>{
      if(element){
        const boundHandler=(e)=>handleOpenState(e,index);
        element.addEventListener("click",boundHandler);

        return ()=>{  
          element.removeEventListener("click",boundHandler);
        }
      }
      return undefined;
    });

    return ()=>{
      cleanUps.forEach((cleanup)=>{
        if(cleanup) cleanup();
      })
    }
  },[handleOpenState]);

  const fetchIngd=()=>{
    axiosInstance.get("business/vendors/getVendorType",{
                      params: {Limit: 100, offset: 0 }
                    })
                  .then(setIngredientClasses)
  }
  
  useEffect(()=>{
    fetchIngd()
  },[]);
  
  // const handleDeleteIngredient=(e,value)=>{
  //   e.stopPropagation();
  //   console.log("delete",value)
  // };

  const [modalState,setModalState] = useState(false);
  const [modalContent,setModalContent] = useState({});
  
  const onModalSave=(newIngredientClass)=>{
    let ingClassCopy = ingredientClasses.slice();
    const foundIndex = ingClassCopy.findIndex(item=>item.Vendor_Id === newIngredientClass.Vendor_Id);
    if(foundIndex !== -1){
      ingClassCopy[foundIndex] = {...newIngredientClass};
      Toaster("success","Successfully Updated Ingredient")
    }else{
      ingClassCopy.push(newIngredientClass);
      Toaster("success","Successfully Added New Ingredient")
    }
    setIngredientClasses(ingClassCopy);
    setTimeout(()=>{
      setModalContent({});
      setModalState(false);
    },2000)
  };

  return (
    <Fragment>
      <div className="flex justify-between w-[80%] items-center mx-auto mt-7">
        <p className="text-2xl font-bold">Ingredients</p>
        <StdButton className={"py-4"} onClick={()=>{setModalState(true);setModalContent({});}}>
          Add Ingredient Class
        </StdButton>
      </div>
      <ScrollableModal modalState={modalState} title={modalContent.Menu_Id ? "Edit Dish" :'ADD New Ingredient'} 
            content={<IngredientModal content={modalContent} onSave={onModalSave}/>} onClose={()=>setModalState(false)}/>
      <div className="hs-accordion-group container grid grid-flow-row grid-cols-1  lg:grid-cols-2  gap-x-8">
        {
          ingredientClasses.map((ingdClass,index)=>(
            <div className={`hs-accordion col-span-1 rounded-2xl  flex-1 m-8 max-h-fit p-1 ${ingdClass.open ? "active" : ""}`} id={`hs-id-${index}`} 
            key={ingdClass.Vendor_Id}>
                

              <button onClick={(e)=>e.stopPropagation()} tabIndex={"-1"}
                  className="cursor-pointer shadow-2xl bg-[#FFC092] w-full h-fit inline-flex items-center justify-between gap-x-3 
                  font-semibold text-start text-gray-800 rounded-2xl p-4"
                  aria-expanded="true" aria-controls={`hs-acc-${index}`}>
                <img src={ingdClass.image_path || MenuTileCover} alt="menu tile cover" className="inline w-[50%] h-[100%] rounded-2xl"/>
                {/* {
                  ingdClass.open ? 
                <DeleteIcon className="size-6 group cursor-pointer" onClick={(e)=>handleDeleteIngredient(e,ingdClass)}>
                  <ToolTip>Delete Ingredient</ToolTip>
                </DeleteIcon> : null
                } */}
                
                  <div className="mx-auto px-3 flex flex-col items-center">
                    
                    <div className="text-2xl">{ingdClass.Vendor_type}</div>
                    
                    <div className={`text-[1rem] mx-auto flex flex-col ${ingdClass.open ? "block" : "hidden"}`}>
                      <input type="button" className="cursor-pointer bg-white rounded-full px-4 py-2 m-1" value="Update Ingredient" 
                          onClick={()=>{setModalContent(ingdClass); setModalState(true);}}
                          />
                    </div>
                  </div>
                     <span ref={(el)=>accordionElementRefs.current[index]=el} className="hs-accordion-toggle group cursor-pointer">
                      <ToolTip>Toggle Ingredient Class</ToolTip>
                      {
                        ingdClass.open ?
                        <UpCollapse className={`size-[2.5rem!important] block`} style={{display: "block" }}/>
                        :
                        <DownCollapse className={`size-[2.5rem!important]`} />
                      }
                    </span>
              </button>
              <div id={`hs-acc-${index}`} 
                className={`hs-accordion-content rounded-b-3xl transition-[height] duration-300 py-4 px-6 mx-3 bg-white min-h-7 w-[95%]
                            ${ingdClass.open ? "block" : "hidden"}`}
                  aria-labelledby={`hs-id-${index}`}>
                    {
                      <Loader>
                          {
                          ingdClass.open &&
                          <Ingredients ingredients={ingdClass.ingredients} ingdClass={ingdClass} refetchIngd={fetchIngd}/>
                          }
                      </Loader>
                    }
              </div>
            </div>
          ))
        }
      </div>
    </Fragment>
  )
}

export default AdminIngredients