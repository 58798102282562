import { toast, Zoom } from "react-toastify";
import { 
   DashboardIcon,FullMenuICon,ChatHistoryIcon, DishesIcon, FixedMenuIcon, IngredientsIcon, LeadsIcon, 
    ManagerDetailsIcon, RaiseTicketIcon, UpComingOrdersIcon, VendorIcon
  } from "@images/sidebarIcons";

export const Toaster=(type,message,overrides={})=>{
  let shortMessage = message
  if(typeof shortMessage!=="string"){
    shortMessage = message?.response?.data || message?.response?.message || message?.message
  }
    return toast[type](shortMessage,{
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Zoom,
        ...overrides
    })
}

export const findObjectByKeyValue=(ArrayofObj, valueToFind,keyName) =>{
  
  
    for (const item of ArrayofObj) {
      // Check if the current object has a matching key-value pair
      if (item[keyName] === valueToFind) {
        return item;
      }
  
      // If the object has children, search recursively in those children
      if (item.children) {
        const result = findObjectByKeyValue(item.children,valueToFind,keyName);
        if (result) {
          return result;
        }
      }
    }
  
    return null; // Return null if no match is found
  }

export  const AdminSideBarMenus=[
    {
      menu: "Dashboard",
      link: "/",
      icon: <DashboardIcon />
    },
    {
      menu: "Menu Management",
      children: [
        {
          menu: "Full Menu",
          icon: <FullMenuICon />,
          link: "/full-menu"
        },
        {
          menu: "Fixed Menu",
          link: "/fixed-menu",
          icon: <FixedMenuIcon />
        },
        {
          menu: "Dishes",
          link: "/dishes",
          icon: <DishesIcon />
        },
        {
          menu: "Ingredients",
          link: "/ingredients",
          icon: <IngredientsIcon />
        },
      ]
    },
    {
      menu: "Business Management",
      children: [
        {
          menu:"Manager Details",
          link: "/managers",
          icon: <ManagerDetailsIcon />
        },
        {
          menu: "Vendor",
          link: "/vendors",
          icon: <VendorIcon />
        }
      ]
    },
    {
      menu: "Order Management",
      children: [
        {
          menu: "Leads",
          link: "/leads",
          icon: <LeadsIcon />
        },
        {
          menu: "Upcoming Orders",
          link: "/upcoming-orders",
          icon: <UpComingOrdersIcon />
        },
        {
          menu: "Chat History",
          link: "/chat-history",
          icon: <ChatHistoryIcon />
        }
      ]
    },
    {
      menu: "Raise Ticket",
      link: "/raise-ticket",
      icon: <RaiseTicketIcon />
    }
  ]

export const AdminProfileMenus=[
    {
      menu: "Profile",
      link: "/profile"
    },
    {
      menu: "Subscriptions",
      link: "/profile"
    },
    {
      menu: "Logout",
      link: "/login"
    }
  ];

export const  isValidUrl=(input)=>{
    // Check if input is a string and not an object or fake path
    if (typeof input !== 'string') {
        return false;
    }
    // Check for valid URL
    try {
        const url = new URL(input);
        // Optionally, check if the URL is a valid HTTP/HTTPS URL
        return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (e) {
        return false; // Not a valid URL
    }
}