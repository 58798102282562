import React,{Fragment} from "react"
import Header from "@components/Header"
import Footer from "@components/Footer"
import { Link } from "react-router-dom"

const PageNotFound = ({isAuthenticated}) => {
  return (
    <Fragment>
        {!isAuthenticated && <Header />}
        <main className="min-h-[82.5vh] max-w-[80%] w-[80%] m-4 top-10">
            <section className="fixed top-[35%] left-[50%] -translate-x-1/2 p-20 text-center min-h-[70vh]">
                <h1 className="text-red-600 text-[1.5rem] md:text-4xl lg:text-7xl font-semibold">404</h1>
                <h2 className="text-xl md:text-2xl lg:text-4xl">Page Not Found</h2>
                <h3 className="text-lg md:text-xl lg:text-xl">
                    The page you requested is not found please 
                    <Link to="/" className="underline text-red-500">Click here</Link> 
                    to go back to Home Page 
                </h3>
            </section>
        </main>
        {!isAuthenticated && <Footer />}
    </Fragment>
  )
}

export default PageNotFound