import React,{ useState} from 'react'
import LoginBot from "@images/login_bot.png";
import ErrorCircle from "@images/ErrorCircle.svg"
import axiosInstance from "apis/config";
import { Toaster } from '@constants/appConstants';
import { useAuth } from '../contexts/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';

const initLogin = {email:"",password:"",emailError:false,passwordError:false};

const Login = () => {
    const [loginForm,setLoginForm] = useState({...initLogin});
    const {login,isAuthenticated} = useAuth();
    const navigate = useNavigate();

    if(isAuthenticated){
        return <Navigate to="/" replace />
    }
    const handleChange=(e)=>{
        const {name,value} = e.target;
        setLoginForm((prev)=>({...prev,[name]:value}));
    };
 
    const handleSubmit=async()=>{
        let loginFormCopy = {...loginForm};
       loginFormCopy.emailError = ! (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(loginFormCopy.email));
       loginFormCopy.passwordError = loginFormCopy.password.trim().length <= 0;

       if(loginFormCopy.emailError || loginFormCopy.passwordError){
           setLoginForm(loginFormCopy);
        }else{
           const resp = await axiosInstance.post("/user/login",loginFormCopy)
                        .catch((ERR)=>Toaster("error",ERR))
                      
            if(resp?.message === "success"){
                setLoginForm({...initLogin});
                Toaster("success", "Succesfully Logged In!");
                login(resp.token);
                navigate("/",{replace:true});
            }
            
       }
    };

  return (
        <section id="login" className="grid grid-cols-2 grid-flow-col gap-0 rounded-full absolute m-auto top-0 bottom-0 left-0 right-0 min-w-36 min-h-36 max-h-fit max-w-fit">
            <div className="px-8 py-12 bg-white rounded-s-2xl">
                <img src={LoginBot} className="w-28 h-28" alt="logo"/>
                <h2 className="text-2xl font-medium">Welcome Back</h2>
                <p>Please enter your credentials to access your account</p>
                
                <div className="my-7">
                <div>
                    <div className="relative">
                    <input type="text" id="email" name="email" placeholder="your@email.com" value={loginForm.email} onChange={handleChange}
                    className={`py-3 px-4 block w-full outline-none rounded-lg border-[2px] border-[#FFC092]
                    ${ loginForm.emailError && " border-red-500 rounded-lg  focus:border-red-500 focus:ring-red-500 "} `} 
                    required="" aria-describedby="email-helper" />
                    { loginForm.emailError &&
                    <div className="absolute inset-y-0 end-0 flex items-center pointer-events-none pe-3">
                        <img src={ErrorCircle} alt="error"/>
                    </div>}
                    </div>
                  { loginForm.emailError && <p className=" text-red-600 mt-2" id="email-helper">Please enter a valid email address.</p> }
                </div>


                <div className="max-w-sm my-3">
                <div className="relative">
                    <input id="hs-toggle-password" type="password" name="password" onChange={handleChange}
                    className={`py-3 ps-4 pe-10 block w-full border-[2px] border-[#FFC092] rounded-lg  outline-none  
                                    ${loginForm.passwordError && " border-red-500 focus:border-red-500 focus:ring-red-500 "} `}
                    placeholder="your password" value={loginForm.password}/>
                    <button type="button" data-hs-toggle-password='{
                        "target": "#hs-toggle-password"
                    }' className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-[#FFC092]">
                    <svg className="shrink-0 size-3.5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path className="hs-password-active:hidden" d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                        <path className="hs-password-active:hidden" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                        <path className="hs-password-active:hidden" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                        <line className="hs-password-active:hidden" x1="2" x2="22" y1="2" y2="22"></line>
                        <path className="hidden hs-password-active:block" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                        <circle className="hidden hs-password-active:block" cx="12" cy="12" r="3"></circle>
                    </svg>
                    {loginForm.passwordError && <img src={ErrorCircle} alt="error"/>}
                    </button>
                </div>
                {  loginForm.passwordError && <p className=" text-red-600 mt-2" id="password-helper">Please enter a valid Password.</p>}
                </div>
                </div>

                <button className="font-semibold text-xl bg-[#FFC092] w-full my-4 p-4 rounded-2xl" onClick={handleSubmit}>
                    Login
                </button>
                <p>Not registered? <a href="/signup" className="text-[#776254] font-bold underline"> Create account </a></p>
                <p>Forgot Password? </p>
            </div>
            <div className="rounded-2xl">
                <img src={LoginBot} alt="login bot" className="rounded-e-2xl"/>
            </div>
        </section>
  )
}

export default Login