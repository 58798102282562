import React,{Fragment} from 'react'
import ContactUsBot from "@images/contact_us_bot.png";

const ContactUs = () => {
  return (
    <Fragment>
        <style>
            {
                `
               
                  #contact-us input,#contact-us textarea,#contact-us .hs-select {
                    border-radius: 20px;
                    padding: 14px;
                    font-size: 16px;
                    min-height: 4rem;
                    border: 1px solid #d3d3d3;
                    box-shadow: 0 0 5pt 0.5pt #D3D3D3;
                    outline: none;
                    margin: 0.5rem 1rem;
                  }
                    #contact-us .hs-select{
                        padding: 7px;
                        width: 100%;
                    }
                    #contact-us div input{
                        width: 100%;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                    }
                    #contact-us input[type=number] {
                    -moz-appearance: textfield;
                    }

                `
            }
        </style>
        <section id="contact-us" className="relative my-7  px-4 lg:mx-[4rem] max-xsm:w-[125%]">
            <div className="grid grid-flow-col grid-cols-2  gap-x-10 w-full">
                <div className="lg:max-w-[43vw] p-3 max-lg:w-full max-lg:col-span-2">
                    <h1 className="text-[#D51037] text-4xl  font-semibold text-nowrap">Contact Us</h1>
                    <p className="font-medium xl:max-w-[80%] text-[1.1rem]">
                        Fill out the form below, and one of our friendly team members will get back to you shortly.
                    </p>
                    <form id="contact-us" className="grid grid-flow-row">
                        <div className="grid grid-flow-rows grid-cols-2 grid-rows-2 gap-x-2">
                        <input type="text" placeholder="Full Name"/>
                        <input type="text" placeholder="Company Name (optional)"/>
                        <input type="mail" placeholder="Email"/>
                        <input type="number" placeholder="Phone Number" 
                        onWheel={(e)=>e.target.blur() && e.stopPropagation() && setTimeout(()=>e.currentTarget.focus(),0)}/>
                        </div>
                        
                        <select disabled value="join-waitlist" data-hs-select='{
                        "placeholder": "Select Subject...",
                        "toggleTag": "<button type=\"button\" aria-expanded=\"false\"></button>",
                        "toggleClasses": "font-semibold hs-select-disabled:pointer-events-none relative py-3 ps-4 pe-9 flex gap-x-2 text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:outline-none",
                        "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300",
                        "optionClasses": "font-semibold py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50",
                        "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"shrink-0 size-3.5 text-blue-600 \" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>",
                        "extraMarkup": "<div class=\"absolute top-1/2 end-3 -translate-y-1/2\"><svg class=\"shrink-0 size-3.5 text-gray-500 \" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"m7 15 5 5 5-5\"/><path d=\"m7 9 5-5 5 5\"/></svg></div>"
                        }' className="hidden">
                            <option value="book-a-demo">Book a demo</option>
                            <option value="join-waitlist">Join the Waitlist - Share your contact and we will follow up</option>
                            <option value="feedback">Feedback</option>
                        </select>
                        <textarea
                            placeholder="Tell us Something"
                            rows="6"
                            className="w-full resize-none"
                        />
                        <button type="submit" className="p-3 w-full m-3 bg-gradient-to-r from-[#c72122] to-[#fc8833] text-white text-lg font-medium rounded-full">
                            Send Message
                        </button>
                    </form>
                </div>
                <div className="max-w-[43vw] max-lg:hidden">
                    <img  src={ContactUsBot} className="scale-[0.8] mx-[20%]" alt="contact us"/>
                </div>
            </div>
        </section>
    </Fragment>
  )
}

export default ContactUs