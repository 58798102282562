import "./App.css"
import "preline/preline";
import "preline/dist/preline";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React,{ Fragment, useEffect } from "react"
import { createBrowserRouter,Navigate,RouterProvider, useLocation } from "react-router-dom";
import LandingPage from "@pages/LandingPage";
import PageNotFound from "@pages/PageNotFound";
import Login from "@pages/Login";
import Signup from "@pages/Signup";
import { AuthProvider, useAuth } from "features/contexts/AuthContext";
import AdminRootLayout from "@pages/AdminPages/AdminRootLayout";
import PurchasePlan, { AdminChildRoutesConfig } from "@pages/AdminPages/PlanRestrictions";

function ProtectedElement({component,requiredPlan,userPlan}){
  const {isAuthenticated} = useAuth();
  const plans = ['Basic', 'Premium', 'Elite'];
  const isPurchased = plans.indexOf(userPlan) >= plans.indexOf(requiredPlan);
  const purchasedPlanCheck =  isPurchased ? component : <PurchasePlan requiredPlan={requiredPlan}/>
  return isAuthenticated ? purchasedPlanCheck : <Navigate to="/login" replace/>
};

const HomePage = ({ loading,isAuthenticated }) => {
  const location = useLocation();

  if (loading) return <div>Loading...</div>; // Show loading state

  // If not authenticated and trying to access dashboard sub-routes, redirect to login
  if (!isAuthenticated && location.pathname !== '/') {
    return <Navigate to="/login" replace />;
  }

  return isAuthenticated ? <AdminRootLayout /> : <LandingPage />;
};

function RouterConfiguration() {
  const {isAuthenticated,loading,user} = useAuth();
  
  const routes =createBrowserRouter([
    { 
      path: "/",
      element:  <HomePage isAuthenticated={isAuthenticated} loading={loading}/>,
      children: AdminChildRoutesConfig.map(({path,element,requiredPlan})=> (
        {
          path,
          element: <ProtectedElement component={element} requiredPlan={requiredPlan} userPlan={user?.company_plan}/>
        }
      ))
    },
    {
      path: "/login",
      element: <Login />
    },
    {
      path: "/signup",
      element: <Signup />
    },
    {
      path: "*",
      element: <PageNotFound isAuthenticated={isAuthenticated}/>
    }
  ]);

  return (
    <Fragment>
      <ToastContainer />
      <RouterProvider router={routes}/>
    </Fragment>
  )
}

function App(){
  let {location} = window;

  useEffect(()=>{
    window.HSStaticMethods?.autoInit();
  },[location.pathname]);
  
  return(
      <AuthProvider>
        <RouterConfiguration />
      </AuthProvider>
  )
}
export default App
