import React, { Fragment } from "react"
import HeroBiryani from "@images/hero_biryani.png";

const Hero = () => {
  return (
    <Fragment>
        <section id="hero" className="mx-auto max-lg:min-h-[90vh] lg:w-[80%] max-lg:mx-2 relative  top-20 grid grid-flow-row lg:grid-flow-col grid-cols-1 lg:grid-cols-2">
            <div className="lg:max-w-[45vw] lg:py-24 lg:px-1">
                <h1 className="text-[2rem] leading-tight font-bold">
                    Elevate your <span className="text-[#C92727]">CATERING</span><br/>
                    with Catermate, your<br />
                    inteliigent <span className="text-[#C92727]">AI PARTNER.</span>
                </h1>
                <p className="my-6 text-[1.3rem]">Enhance your catering business with cutting-edge <br className="max-lg:hidden" />AI technology.</p>
                <a className="text-white text-[1.5rem] bg-red-700 rounded-full py-4 px-5 my-5" href="/login">
                    Subcsribe Now
                </a>
            </div>
            <div className="lg:max-w-[43vw] p-7 relative ">
                <img alt="biryani" src={HeroBiryani} className="relative bottom-0 right-0 md:scale-75  max-2xl:-scale-75"/>
            </div>
        </section>
    </Fragment>
  )
}

export default Hero