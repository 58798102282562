import { createContext, useCallback, useContext, useEffect, useState,useMemo } from 'react';
import { jwtDecode } from 'jwt-decode';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
      const {sub} = jwtDecode(token);
      setUser(sub);
    } else {
      setIsAuthenticated(false);
    }
    setLoading(false);
  }, []);

  const login = useCallback((data) => {
    const {sub} = jwtDecode(data);
    sessionStorage.setItem('token', data);
    // sessionStorage.setItem('user',JSON.stringify(user));
    setUser(sub);
    setIsAuthenticated(true);
  },[]);

  const logout = useCallback(() => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    setUser(null);
    setIsAuthenticated(false);
  },[]);

  const valueObject = useMemo(()=>(
    {
      isAuthenticated,login,logout,loading,user
    }
  ),[isAuthenticated,login,logout,loading,user])

  return (
    <AuthContext.Provider
      value={valueObject}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
