import React, { useState,Fragment } from 'react'
import { RightArrow,LeftArrow,DoubleRight,DownArrow,TickMark } from "@images/sidebarIcons";


const AdminUpcomingOrders = () => {
    const [upOrdersList,setUpOrdersList] = useState([
        {orderId: 1,name: "Name 1",fnName: "fn 1",stDate: "2020-01-01",endDate: "2020-01-03",venue: "Venue 1",clNo: "+911234567890" },
        {orderId: 2,name: "Name 2",fnName: "fn 2",stDate: "2020-02-02",endDate: "2020-02-04",venue: "Venue 2",clNo: "+911234567890" },
        {orderId: 3,name: "Name 3",fnName: "fn 3",stDate: "2020-03-03",endDate: "2020-03-05",venue: "Venue 3",clNo: "+911234567890" },
        {orderId: 4,name: "Name 4",fnName: "fn 4",stDate: "2020-04-04",endDate: "2020-04-06",venue: "Venue 4",clNo: "+911234567890" },
        {orderId: 5,name: "Name 5",fnName: "fn 5",stDate: "2020-05-05",endDate: "2020-05-07",venue: "Venue 5",clNo: "+911234567890" },
        {orderId: 6,name: "Name 6",fnName: "fn 6",stDate: "2020-06-06",endDate: "2020-06-08",venue: "Venue 6",clNo: "+911234567890" }
    ]);
    const columns=[{name: "Order Id",key: "orderId"},{name: "Name",key:"name"},{name: "functionName",key:"fnName"},{name: "Start Date",key:"stDate"},
                    {name: "End Date",key:"endDate"},{name: "Venue",key:"venue"},{name: "Client Number",key:"clNo"}]
  return (
    <Fragment>
        <div className="flex justify-between w-[80%] items-center mx-auto mt-7">
            <p className="text-2xl font-bold place-content-start">Upcoming Orders</p>
        </div>
        <div className=" w-[80%] mx-auto my-7">
            <div className="rounded-2xl overflow-hidden shadow-md">
                <table className="rounded-2xl table-fixed min-w-full">
                    <thead className="rounded-2xl">
                        <tr className="bg-[#FFC092] rounded-2xl">
                            {
                                columns.map((col)=>(
                                    <th key={col.key} className="p-4 text-center">{col.name}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            upOrdersList.map((row,rowIndex)=>(
                                <tr key={row.orderId} className={`p-2 ${rowIndex%2 === 0 && " bg-white "}`}>
                                    {
                                        columns.map((col)=>(
                                            <td key={col.key} className="p-2 text-center"> {row[col.key]} </td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                    <tfoot>
                        <tr className="bg-white border-t-[1px] border-gray-300">
                        <td className="text-center p-4" colSpan={columns.length -  3}>
                    
                            <nav className="flex justify-end items-center -space-x-px border-[1px] w-fit rounded-full  border-gray-400" aria-label="Pagination">
                                <button type="button" className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-1.5 text-sm first:rounded-s-full last:rounded-e-full border border-gray-200 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none" aria-label="Previous">
                              
                                <LeftArrow className="shrink-0 size-4"/>
                                <span className="sr-only">Previous</span>
                                </button>
                                <button type="button" className="min-h-[38px] min-w-[38px] flex justify-center items-center bg-[rgb(255,202,138)] text-gray-800 border border-gray-200 py-2 px-3 text-sm first:rounded-s-full last:rounded-e-full focus:outline-none focus:bg-gray-300 disabled:opacity-50 disabled:pointer-events-none" aria-current="page">1</button>
                                <button type="button" className="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm first:rounded-s-full last:rounded-e-full focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">2</button>
                                <button type="button" className="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm first:rounded-s-full last:rounded-e-full focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">3</button>
                                <div className="hs-tooltip inline-block border border-gray-200">
                                <button type="button" className="hs-tooltip-toggle group min-h-[36px] min-w-[36px] flex justify-center items-center text-gray-400 hover:text-blue-600 p-2 text-sm focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                                    <span className="group-hover:hidden text-xs text-black">•••</span>
                                    <DoubleRight className="group-hover:block hidden shrink-0 size-5 text-gray-800"/>
                                    <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm hidden" role="tooltip">
                                    Next 4 pages
                                    </span>
                                </button>
                                </div>
                                <button type="button" className="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm first:rounded-s-full last:rounded-e-full focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">8</button>
                                <button type="button" className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-1.5 text-sm first:rounded-s-full last:rounded-e-full border border-gray-200 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none" aria-label="Next">
                                <RightArrow className="shrink-0 size-4"/>
                                <span className="sr-only">Next</span>
                                </button>
                            </nav>
                        </td>

                                <td className="text-center p-4" colSpan={2}>
                                <div className="hs-dropdown [--placement:bottom-left] relative inline-flex">
                                <button id="hs-default-pilled-bordered-group-pagination-dropdown" type="button" className="hs-dropdown-toggle py-2 px-2.5 inline-flex items-center gap-x-1 text-sm rounded-lg border border-gray-200 text-gray-800 shadow-sm bg-white focus:outline-non disabled:opacity-50 disabled:pointer-events-none" aria-haspopup="menu" aria-expanded="false" aria-label="Dropdown">
                                    5 Rows per page
                                    <DownArrow className="shrink-0 size-4"/>
                                </button>
                                <div className="hs-dropdown-menu hs-dropdown-open:opacity-100 w-18 hidden z-50 transition-[margin,opacity] opacity-0 duration-300 mb-2 bg-white shadow-md rounded-lg p-1 space-y-0.5" role="menu" aria-orientation="vertical" aria-labelledby="hs-default-pilled-bordered-group-pagination-dropdown">
                                    <button type="button" className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                                    5
                                    <TickMark className="ms-auto shrink-0 size-4 text-gray-700"/>
                                    </button>
                                    <button type="button" className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                                    8
                                    </button>
                                    <button type="button" className="w-full flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                                    10
                                    </button>
                                </div>
                                </div>
                                </td>

                                <td className="text-center p-4">
                                    <div className="flex items-center justify-center gap-x-2">
                                    <span className="text-sm text-gray-800 whitespace-nowrap">
                                        Go to
                                    </span>
                                    <input type="number" 
                                        className="min-h-[38px] py-2 px-2.5 block w-12 outline-none border-[2px] border-gray-200 rounded-lg text-sm text-center
                                         [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none disabled:opacity-50 disabled:pointer-events-none" 
                                        style={{"MozAppearance": "textfield"}} />
                                    <span className="text-sm text-gray-800 whitespace-nowrap">
                                        page
                                    </span>
                                    </div>
                                </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            
        </div>
    </Fragment>
  )
}

export default AdminUpcomingOrders