import AdminDashboard from "@pages/AdminPages/AdminDashboard";
import AdminFullMenu from "@pages/AdminPages/AdminFullMenu";
import AdminRaiseTicket from "@pages/AdminPages/AdminRaiseTicket";
import AdminFixedMenu from "@pages/AdminPages/AdminFixedMenu";
import AdminIngredients from "@pages/AdminPages/AdminIngredients";
import AdminManager from "@pages/AdminPages/AdminManager";
import AdminVendor from "@pages/AdminPages/AdminVendor";
import AdminLead from "@pages/AdminPages/AdminLead";
import AdminUpcomingOrders from "@pages/AdminPages/AdminUpcomingOrders";
import AdminChatHistory from "@pages/AdminPages/AdminChatHistory";
import AdminProfile from "@pages/AdminPages/AdminProfile";
import AdminDishes from "@pages/AdminPages/AdminDishes";
import AdminSubscription from "@pages/AdminPages/AdminSubscription";

export const AdminChildRoutesConfig=[
    { path: "/",
      index: true,
      element:  <AdminDashboard />,
      requiredPlan: "Premium",
    },
    {
      path: "raise-ticket",
      element:  <AdminRaiseTicket />,
      requiredPlan: "Premium",
    },
    {
      path: "full-menu",
      element:  <AdminFullMenu />,
      requiredPlan: "Basic",
    },
    {
      path: "fixed-menu",
      element:  <AdminFixedMenu />,
      requiredPlan: "Basic"
    },
    {
      path: "ingredients",
      element:  <AdminIngredients />,
      requiredPlan: "Elite"
    },
    {
      path: "managers",
      element:  <AdminManager />,
      requiredPlan: "Elite",
    },
    {
      path: "vendors",
      element:  <AdminVendor />,
      requiredPlan: "Elite",
    },
    {
      path: "leads",
      element:  <AdminLead />,
      requiredPlan: "Basic",
    },
    {
      path: "upcoming-orders",
      element:  <AdminUpcomingOrders />,
      requiredPlan: "Elite"
    },
    {
      path: "chat-history",
      element:  <AdminChatHistory />,
      requiredPlan: "Elite"
    },
    {
      path: "profile",
      element:  <AdminProfile />,
      requiredPlan: "Basic"
    },
    {
      path: "dishes",
      element:  <AdminDishes />,
      requiredPlan: "Basic"
    },
    {
      path: "subscriptions",
      element: <AdminSubscription />,
      requiredPlan: "Basic"
    }
  ] 

const PurchasePlan=({requiredPlan})=>{

    return(
        <div className="flex flex-col items-center justify-center h-full">
            <p className="text-xl inline-block "> 
                This Plan is for <span className="text-red-500 underline">{requiredPlan}</span> users only 
            </p>
            <a href="/subscriptions" className="text-red-500 inline-block  text-xl font-semibold underline">Purchase Now</a>
        </div>
    )
}

export default PurchasePlan;