import { FileUpload } from '@components/UtilityComponents'
import React, { Fragment } from 'react'

const AdminRaiseTicket = () => {
  return (
    <Fragment>
      <style>
        {
          `
          #raise-ticket input,#raise-ticket textarea,#raise-ticket .hs-select {
            border-radius: 20px;
            padding: 14px;
            font-size: 16px;
            min-height: 4rem;
            border: 1px solid #d3d3d3;
            box-shadow: 0 0 5pt 0.5pt #D3D3D3;
            outline: none;
            margin: 0.5rem 1rem;
          }
            #raise-ticket .hs-select{
                padding: 7px;
                width: 50%;
            }
            #raise-ticket input{
                width: 50%;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }
            #raise-ticket input[type=number] {
            -moz-appearance: textfield;
            }
          `
        }
      </style>
        <div  className="flex justify-between w-[80%] items-center mr-auto mt-7">
            <p className="text-2xl font-bold place-content-start">Raise Ticket</p>
        </div>
        <div className="mx-auto w-[80%]" id="raise-ticket">
          <form onChange={(e)=>e.preventDefault()} onSubmit={(e)=>e.preventDefault()}>
        
          <select disabled value="join-waitlist" data-hs-select='{
          "placeholder": "Select Subject...",
          "toggleTag": "<button type=\"button\" aria-expanded=\"false\"></button>",
          "toggleClasses": "font-semibold hs-select-disabled:pointer-events-none relative py-3 ps-4 pe-9 flex gap-x-2 text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:outline-none",
          "dropdownClasses": "mt-[1px!important] z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300",
          "optionClasses": "font-semibold py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50",
          "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"shrink-0 size-3.5 text-blue-600 \" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>",
          "extraMarkup": "<div class=\"absolute top-1/2 end-3 -translate-y-1/2\"><svg class=\"shrink-0 size-3.5 text-gray-500 \" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"m7 15 5 5 5-5\"/><path d=\"m7 9 5-5 5 5\"/></svg></div>"
          }' className="hidden">
              <option value="whatsapp">Whatsapp Related Issue</option>
              <option value="payment">Payment Related Issue</option>
              <option value="app-related">This Application related issue</option>
              <option value="other">others</option>
          </select>
          <input type="text" placeholder="Short Description" />
          <textarea
              placeholder="Tell us Something"
              rows="6"
              className="w-[80%] resize-none block"
          />
          <FileUpload />
          <button type="submit" className="p-3 px-12 w-fit m-3 bg-[#FFC092] text-lg font-medium rounded-full">
              Submit
          </button>
          </form>
        </div>
    </Fragment>
  )
}

export default AdminRaiseTicket