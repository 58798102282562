import React, { Fragment,useCallback,useEffect,useReducer,useRef,useState } from 'react'
import MenuTileCover from "@images/DishPlaceholder.jpg";
import { DownCollapse, UpCollapse,DeleteIcon,CancelSub,PlusIcon } from '@images/sidebarIcons';
import {SelectReact,SingleFileUpload,Loader,
        TextTruncateTooltip,ScrollableModal,ToolTip } from '@components/UtilityComponents';
import axiosInstance,{baseURL} from 'apis/config';
import { Toaster,isValidUrl } from '@constants/appConstants';

const SubCategory=({subCatCollection,refetchSub})=>{
  
  const handleSubItems = (state,action)=>{ 
      switch (action.type) {
        case "add":
              return [...state,action.data];

        case "delete":
              return state.filter((elem,index)=>index!==action.deleteIndex);

        default:
            return subCatCollection.dishes;
      }
  };

  const [addOrEditState,setAddOrEditState] = useState(isNaN(subCatCollection.sub_category_id));
  const [subName,setSubName] = useState(subCatCollection?.sub_category_name || "");
  const [subItems,setSubItems] = useReducer(handleSubItems,subCatCollection.dishes);
  const [newSubCatDish,setNewSubCatDish] = useState("");
  const [dishesOption,setDishesOption] = useState([]);
  const [optionsParams,setOptionsParams] = useState({
     Limit: 15, Offset: 0, Search_text: "" 
  });

  const handleSaveOrEdit=()=>{
    // if edit state is  opened 
      if(addOrEditState){
         if(subItems.length > 0 && subName.trim().length > 0 ){
          let jsonBody={};
          //if it is a new subcategory
          if( isNaN(subCatCollection.sub_category_id) ){
            let newSubCategoryRequest = {
              category_id: subCatCollection.category_id,
              sub_category_name: subName,
              sub_category_id: null,
              newDishes: subItems,
              deletedDishes: []
            }
            // console.log(newSubCategoryRequest," NEW SUB SAVE");
            jsonBody = newSubCategoryRequest
          }else{ //if it is an existing subcategory
            let updateSubCategory = {
              category_id: subCatCollection.category_id,
              sub_category_name: subName,
              sub_category_id: subCatCollection.sub_category_id,
              deletedDishes: subCatCollection.dishes.filter((dish)=> {
                return !subItems.some((subItem)=> subItem.Menu_Id === dish.Menu_Id )
              }),
              newDishes: subItems.filter((subItem)=>!subItem.sub_category_id)
            }
            // console.log(updateSubCategory, " UPDATE SUB SAVE")
            jsonBody = updateSubCategory
          }
          axiosInstance.put("menus/full-menu-subcategory",jsonBody)
            .then((resp)=>{
              if(resp){
                refetchSub()
                setAddOrEditState(false)
                Toaster("success","Successfully Updated subcategory content")
              }
            })
            .catch(console.error)
         }else{
          Toaster("error","The Subcategory Name shouldn't be empty")
          Toaster("error","Specify atleast one dish in the subcategory")
         }
      }else{
        setAddOrEditState(!addOrEditState)
      }
  }
  
  const getDishesForOptions=(params)=>{

    axiosInstance.get("/menus/options-for-full-menu",{
      params: {...params }
    })
    .then((data)=>{
        setDishesOption((prev)=>params.Offset > 0 ? [...prev,...data] : data)
    })
    .catch((err)=>Toaster("error",err))
  }

  useEffect(()=>{
    if(addOrEditState)
    getDishesForOptions(optionsParams)
  },[addOrEditState,optionsParams]);

  const onInputChange=useCallback((value)=>{
    if(value.length > 3 || value.trim()==="")
      setOptionsParams((prev)=>(
        {...prev,Offset:0, Search_text: value.trim()}
      ));
  },[]);

  const handleDelete=()=>{
    let confirmation = window.confirm(`Are you sure you want to delete ${subCatCollection?.sub_category_name}`);
    if(confirmation){
      // if it is a new subcategory which is not yet saved , just refetch the sub
      if( isNaN(subCatCollection.sub_category_id) ){
        refetchSub()
      }else{
        axiosInstance.delete("menus/full-menus",{
           params:{ 
              delete_subcategories:subCatCollection.sub_category_id
           }
          })
          .then((resp)=>{
            if(resp.deleted_subcategory){
              Toaster("success","Successfully deleted the subcategory");
              refetchSub()
            }
          })
          .catch((err)=>Toaster("error",err))
      }
    }
  };

  return(
    <Fragment>
      <div className="flex justify-between my-4">
        <div className='group'>
          <input type="text" value={subName} onChange={(e)=>setSubName(e.target.value)} 
              placeholder="Subcategory name" autoFocus={addOrEditState}
              className={`py-3 px-4  inline rounded-full outline-none
                        ${addOrEditState ? " shadow-inner border-[1px] focus:border-gray-600 focus:ring-gray-600 border-gray-600 " 
                            : "pointer-events-none"}
                        `}
            />
          <DeleteIcon className="inline my-2 align-middle size-5 cursor-pointer" onClick={handleDelete}/>
          <ToolTip>Delete SubCategory</ToolTip>
        </div>
        <div>

          <button className="underline mx-2 text-[1rem]" onClick={handleSaveOrEdit}>
            {addOrEditState ? "Save the Items" : "Add or Edit Items"}
          </button>

          {/*cancel the editing only if it is not a new subcategory which is not saved*/}
          <button className={`mx-2 underline text-[1rem] 
          ${addOrEditState && !isNaN(subCatCollection.sub_category_id) ? "inline": "hidden"}`} 
            onClick={()=>{ 
              setAddOrEditState(false); 
              setSubItems({type:"default"}); 
              setSubName(subCatCollection.sub_category_name)}
            }>
            Cancel Editing
          </button>

        </div>
      </div>
      <div className="grid grid-flow-row grid-cols-4 my-4">
        {
          subItems.map((subItem,subIndex)=>{
            return(
              <span key={subItem.Menu_Id} className="flex rounded-full bg-[#FFF1DB] p-3 m-1 relative">
              <span className=" text-center inline-flex text-wrap break-words break-all w-fit h-fit justify-center 
                                align-middle text-sm items-center m-auto content-center">
                {subItem.Menu_Name}
              </span>
               {addOrEditState && 
               <CancelSub className="absolute inline right-0 cursor-pointer" 
                  onClick={()=>setSubItems({type: "delete",deleteIndex: subIndex})} />}
              </span>
            )
          })
        }
      </div>
      <div className={`max-w-sm space-y-3 ${addOrEditState ? "block" : "hidden"} `}>
        <div className='flex'>

           <SelectReact
            label="Select a Dish"
            options={ dishesOption}
            value={newSubCatDish}
            onLoadMore={()=>
              setOptionsParams((prev)=>(
              { ...prev,Offset: prev.Offset+1 }
            ))}
            onChange={setNewSubCatDish}
            onInputChange={onInputChange}
            isLoading={false}
            getOptionLabel={(opt)=>opt.Menu_Name}
            getOptionValue={(opt)=>opt.Menu_Id}
            hasMore={true}
          />
          <div className=" p-2 shadow-md shadow-gray-500   border-[1px] border-gray-600 rounded-2xl 
                            bg-[#FFC092] inset-y-0 end-0
                            inline-flex items-center cursor-pointer z-20 m-2" 
                             onClick={()=>{
                              if(typeof newSubCatDish === "object")
                              setSubItems({type : "add", data: newSubCatDish}); 
                              setNewSubCatDish("") 
                            }}> 
              Add <PlusIcon className="rounded-md size-3 " />
            </div>
        </div>
      </div>
      <hr className="h-[2px] border-[1px] border-gray-300 my-4"/>
    </Fragment>
  )
}

const CategoryModal=({content,onSave})=>{
  
  const [categoryName,setCategoryName] = useState(content.category_name);
  const photoRef = useRef();
  const [catPhoto,setCatPhoto] = useState(content.Image_Path);
  

  const handlePhotoReset=()=>{
    setCatPhoto(content.Image_Path)
  }
  const handleSave=()=>{
    const HTTP_METHOD = content.category_id ? "put" : "post";
    const formData = new FormData();
    if(content.category_id)
    formData.append("category_id",content.category_id);
    formData.append("category_name",categoryName);
    // if it is  a valid http or has a valid value in photo
    if(catPhoto){
      let image = isValidUrl(catPhoto) ? catPhoto.replace(baseURL,"") : catPhoto
      formData.append("image_Path",image)
    }
    // if new image is not same as old image and it is valid value,(not undefined or not null)
    if(content.Image_Path !== catPhoto && content.Image_Path){
      formData.append("oldImage",content.Image_Path)
    }
    axiosInstance[HTTP_METHOD]("menus/full-menus",formData,{
      headers:{
        'Content-Type': "multipart/form-data"
      }
    })
    .then((resp)=> {
      if(resp.category_id)
      onSave(resp)
    else
      Toaster("error",resp) 
    }
  )
    .catch((err)=>Toaster("error",err))
  }
  return(
      <div className='flex flex-col p-6 m-3'>
        <SingleFileUpload image={catPhoto || MenuTileCover} 
                                  fileInputRef={photoRef} 
                                  handleChange={setCatPhoto} handleReset={handlePhotoReset}
                                  imageClass={"shrink w-ful h-40 rounded-2xl"}
                                  />
          <input value={categoryName} onChange={(e)=>setCategoryName(e.target.value)} 
                placeholder="Category name" autoFocus={true}
                className={`py-3 px-4 my-5 mx-auto  inline rounded-full outline-none w-[50%]
                           shadow-inner border-[1px] focus:border-gray-600 focus:ring-gray-600 border-gray-600`}
              />
      <button type="button" onClick={handleSave}
          className="py-2 px-3 w-fit mx-auto my-4 inline-flex items-center gap-x-2 text-[1rem] font-semibold rounded-lg 
          border border-gray-400 bg-[#FFC092] text-gray-800" 
          >
          Save changes
      </button>
      </div>
  )
};

const AdminFullMenu = () => {
  const [categoryDetail,setCategoryDetail] = useState([]);
  const accordionElementRefs = useRef([]);

  const addSubCat=(categoryCopy,index)=>{   

    const currentCAT = categoryCopy[index]
     axiosInstance.get("/menus/full-menu-subcategories",{
      params: { category_id: currentCAT.category_id } 
    })
    .then((subCategories)=>{
      categoryCopy[index].subCategories = subCategories;
      setCategoryDetail(categoryCopy);
    })

  };
  

  const updateCategoryDetail=useCallback((index,newSub)=>{
    let categoryCopy = categoryDetail.slice();
    
    // if adding new sub let the toggle be true else toggle the open state
    const toggleState = newSub ? true :  !categoryDetail[index].open;
    //reset toggle
    categoryCopy = categoryCopy.map((accItem)=>{accItem.open=false; return accItem;})
    categoryDetail[index].open = toggleState;
    categoryCopy[index].open = toggleState;
    
    if(newSub){
      let CURRENT_CATEGORY = categoryCopy[index];
      const totalExistingNEWSubcat = CURRENT_CATEGORY.subCategories
                                      .filter((subcat)=>isNaN(subcat.sub_category_id)).length
        CURRENT_CATEGORY.subCategories = [
         { 
          category_id: CURRENT_CATEGORY.category_id,
          sub_category_id: "newSub-"+totalExistingNEWSubcat,
          sub_category_name: "new",
          dishes: []
         },
        ...CURRENT_CATEGORY.subCategories,
      ]
      
      categoryCopy[index] = CURRENT_CATEGORY;

      setCategoryDetail(categoryCopy)
      return;
    };

    if(!categoryCopy.subCategories){
      addSubCat(categoryCopy,index);
    }else{
      setCategoryDetail(categoryCopy);
    }
  },[categoryDetail]);
  
  const handleOpenState=useCallback((e,index)=>{
      e?.stopPropagation();
      e?.preventDefault();
      updateCategoryDetail(index) 
    
  },[updateCategoryDetail]);

  useEffect(()=>{
    const cleanUps = accordionElementRefs.current.map((element,index)=>{
      if(element){
        const boundHandler=(e)=>handleOpenState(e,index);
        element.addEventListener("click",boundHandler);

        return ()=>{  
          element.removeEventListener("click",boundHandler);
        }
      }
      return undefined;
    });

    return ()=>{
      cleanUps.forEach((cleanup)=>{
        if(cleanup) cleanup();
      })
    }
  },[handleOpenState]);

  const fetchCategoryList=()=>{
    axiosInstance.get("/menus/full-menus")
    .then(setCategoryDetail)
  }

  useEffect(()=>{
    fetchCategoryList()
  },[]);
 
  const [categoryModalContent,setCategoryModalContent]= useState({});
  const onModalSave=(newCategory)=>{
    let categoryCopy = categoryDetail.slice();
    const foundIndex = categoryCopy.findIndex(item=> item.category_id === newCategory.category_id)
    if(foundIndex !== -1){
      categoryCopy[foundIndex] = {...categoryCopy[foundIndex],...newCategory}
      Toaster("success","Successfully Updated the Category");
      
    }else{
      categoryCopy.push(newCategory)
      Toaster("success","Successfully Added a New Category");
    }
    setTimeout(()=>setCategoryModalContent({}),2000)
    setCategoryDetail(categoryCopy)
  };

  const handleDeleteCategory=(category)=>{
    let confirmation = window.confirm(`Are you sure you want to delete ${category?.category_name}`);
    if(confirmation){
     
        axiosInstance.delete("menus/full-menus",{
           params:{ 
              delete_categories:category.category_id
           }
          })
          .then((resp)=>{
            if(resp.deleted_category){
              Toaster("success","Successfully deleted the category");
              fetchCategoryList()
            }
          })
          .catch((err)=>Toaster("error",err))
    
    }
  };

  return (
    <Fragment>
      <div className="flex justify-between w-[80%] items-center mx-auto mt-7">
        <p className="text-2xl font-bold">Menus</p>
        <button className="cursor-pointer rounded-full font-semibold px-6 py-3 m-1.5 shadow-xl bg-[#FFC092]" 
          onClick={()=>{setCategoryModalContent({open:true}); }}
          >
          Add Category
        </button>
      </div>

         <ScrollableModal modalState={categoryModalContent.open}
          title={ categoryModalContent.category_id ? "Update Category" : "Add New Category" } 
          content={<CategoryModal content={categoryModalContent} onSave={onModalSave}/>} 
          onClose={()=>setCategoryModalContent({})}
         /> 

      <div className="hs-accordion-group container grid grid-flow-row grid-cols-1  lg:grid-cols-2  gap-x-8">
        {
          categoryDetail?.map((value,index)=>{
            return(
            <div className={`hs-accordion col-span-1 rounded-2xl  flex-1 m-8 max-h-fit p-1 ${value.open ? "active" : ""}`} 
                id={`hs-id-${index}`} 
                key={value.category_id}>
              <div onClick={(e)=>e.stopPropagation()}
                  className="shadow-2xl bg-[#FFC092] w-full h-fit inline-flex items-center justify-between gap-x-3 
                  font-semibold text-start text-gray-800 rounded-2xl p-4"
                  aria-expanded="true" aria-controls={`hs-acc-${index}`}>
                <img src={value.Image_Path || MenuTileCover} alt="menu tile cover" 
                  className="inline max-w-48 max-h-48 overflow-clip min-h-48 min-w-48 aspect-square rounded-2xl"/>
                  {value.open && 
                  <span className='group'>
                    <ToolTip>Delete Category</ToolTip>
                    <DeleteIcon className="inline my-2 align-middle size-10 cursor-pointer" onClick={()=>handleDeleteCategory(value)}/>
                  </span>
                  }
                  <div className="mx-auto px-3 flex grow-0 shrink max-w-[40%] flex-col items-center">
                    
                    <TextTruncateTooltip content={value.category_name} 
                          className="truncate max-w-full whitespace-nowrap h-fit text-2xl mx-auto"/>

                    <div className={`text-[1rem] mx-auto flex flex-col ${value.open ? "block" : "hidden"}`}>
                      <button type="button" className="cursor-pointer bg-white rounded-full px-4 py-2 m-1" 
                       onClick={()=>{ setCategoryModalContent({open:true,...value}) }}>
                          Update Category
                        </button>
                        <button className="cursor-pointer bg-white rounded-full px-4 py-2 m-1" onClick={()=>updateCategoryDetail(index,"new")}>
                          Add SubCategory
                        </button>
                    </div>

                  </div>
                     <span ref={(el)=>accordionElementRefs.current[index]=el} className="group hs-accordion-toggle cursor-pointer">
                     <ToolTip>Toggle Category</ToolTip>
                      {
                        value.open ?
                        <UpCollapse className={`size-[2.5rem!important] block`} style={{display: "block" }}/>
                        :
                        <DownCollapse className={`size-[2.5rem!important]`} />
                      }
                    </span>
              </div>
              <div id={`hs-acc-${index}`} 
                className={`hs-accordion-content w-full transition-[height] duration-300 py-4 px-8 rounded-2xl bg-white
                            ${value.open ? "block" : "hidden"}`}
                  aria-labelledby={`hs-id-${index}`}>
                  {
                    value.open ? 
                      <Loader>
                        { 
                        value.subCategories?.map((subCatCollection)=>{
                          return(
                            <Fragment key={subCatCollection.sub_category_id}>
                              <SubCategory subCatCollection={subCatCollection} refetchSub={()=>addSubCat(categoryDetail.slice(),index)}/>
                            </Fragment>
                          )
                        })  
                        } 
                      </Loader>
                    :
                      null
                  }
              </div>
            </div>
            )
          })
        }
        
      </div>
    </Fragment>
  )
}

export default AdminFullMenu