import React, { Fragment, useState,useRef, useEffect, useCallback } from 'react'
import ProfileAvatar from "@images/profile_avatar.png";
import { SingleFileUpload } from "@components/UtilityComponents";
import Whatsapp from "@images/whatsapp.png";
import axiosInstance from 'apis/config';
import { useAuth } from 'features/contexts/AuthContext';
import { Toaster } from '@constants/appConstants';

const AdminProfile = () => {

  const [userFields,setUserFields] = useState({});
  const [companyFields,setCompanyFields] = useState({});
  const [passwordFields,setPasswordFields] = useState({});

  const [userPhoto,setUserPhoto] = useState();
  const [companyLogo,setCompanyLogo] = useState();
  const userPhotoRef = useRef(null);
  const companyLogoRef = useRef(null);
  
  const {user} = useAuth();
  
  const getUserAndCompanyDetails=useCallback(()=>{
    axiosInstance.get("user/"+user.user_id)
                .then((response)=>{
                  let {user,company} = response;
                  setUserFields(user); 
                  setCompanyFields(company);
                  setCompanyLogo(company.Logo);
                  setUserPhoto(user.photo)
                 })
                .catch((err)=>Toaster("error",err))
  },[user?.user_id]);

  useEffect(()=>{
    getUserAndCompanyDetails();
  },[getUserAndCompanyDetails]);

  const handleUserChange=(e)=>{
    let {value,name} = e.target;
    if(name==="phone_number"){
      value = value.replace(/\D/g,"")
    }
    setUserFields((prev)=>({...prev,[name]:value}))
  };

  const handleCompanyChange=(e)=>{
    let {value,name} = e.target;
    if(name==="Mobile_Number"){
      value = value.replace(/\D/g,"")
    }
    setCompanyFields((prev)=>({...prev,[name]:value}))
  }

  const handlePasswordChange=(e)=>{
    let {value,name} = e.target;
    setPasswordFields((prev)=>({...prev,[name]:value}))
  }

  return (
    <Fragment>
       <div className="flex justify-between w-[90%] items-center mx-auto mt-7">
            <p className="text-2xl font-bold place-content-start">Profile</p>
        </div>
        <div className="w-[90%] grid grid-flow-row grid-cols-12 mx-auto">
            <div className="col-span-8 m-2 ">
                <p className="text-xl font-medium">Company Section</p>
                <div className="bg-white shadow-lg rounded-3xl p-[10px] flex flex-col justify-between ">
                    <SingleFileUpload image={companyLogo || ProfileAvatar} imageClass="rounded-md m-3  h-40"  fileInputRef={companyLogoRef}
                        handleChange={setCompanyLogo} handleReset={()=>setCompanyLogo()}
                      />

                      {/*company name email contact*/}
                    <input type="text" placeholder="Company Name" name="Company_Name" 
                            value={companyFields.Company_Name} onChange={handleCompanyChange}
                            className="block outline-none m-2  shadow-md border border-gray-400 rounded-3xl p-3"/>
                    <div className='flex'>
                      <input type="email" placeholder="Company e-mail" name="Email_Id"
                              value={companyFields.Email_Id} onChange={handleCompanyChange}
                              className='w-[50%]  outline-none m-2  shadow-md border border-gray-400 rounded-3xl p-3'/>
                      <input type="tel" inputMode='numeric' placeholder="Company number" name="Mobile_Number"
                            value={companyFields.Mobile_Number} onChange={handleCompanyChange}
                            className='w-[50%]  outline-none m-2  shadow-md border border-gray-400 rounded-3xl p-3'/>
                    </div>
                    {/*welcome text and whatsapp bot*/}
                    <div className="flex shadow-md m-2 border border-gray-400 rounded-3xl">
                        <img src={Whatsapp} alt="whatsapp" className='size-10 overflow-clip rounded-3xl border border-gray-400 w-[2.875rem] h-[2.875rem]'/>
                      <input type="tel" placeholder='Whatsapp bot number' inputMode='numeric'  name="Whatsapp_bot"
                            value={companyFields.Whatsapp_bot} onChange={handleCompanyChange}
                            className="w-[90%] outline-none py-3 px-4 block border-gray-200 shadow-sm focus:z-10" />
                    </div>
                    <input type='text' placeholder='Welcome Text' name="Welcome_Text"
                            value={companyFields.Welcome_Text} onChange={handleCompanyChange}
                            className='outline-none m-2  shadow-md border border-gray-400 rounded-3xl p-3'/>
                    
                    {/*Address Details*/}
                    <textarea rows={5} placeholder='Address' name="Address"
                      value={companyFields.Address} onChange={handleCompanyChange}
                      className='resize-none  outline-none m-2  shadow-md border border-gray-400 rounded-3xl p-3'/>
                    <div className='flex'>
                      <input type='text' placeholder='State' name="State"
                            value={companyFields.State} onChange={handleCompanyChange}
                            className='w-[50%]  outline-none m-2  shadow-md border border-gray-400 rounded-3xl p-3'/>
                      <input type='text' placeholder='City' name="City"
                            value={companyFields.City} onChange={handleCompanyChange}
                            className='w-[50%]  outline-none m-2  shadow-md border border-gray-400 rounded-3xl p-3'/>
                    </div>

                    <button className="bg-[#FFC092] shadow-xl rounded-2xl px-6 py-1 mx-auto text-xl font-medium m-4">Save</button>
                  <div>

                  </div>
                </div>
            </div>
            <div className="col-span-4 m-2 p-3 ">
                <p className="text-xl font-medium">Personal Section</p>
                <div className="bg-white shadow-lg rounded-3xl p-[10px] flex flex-col justify-between">
                  <SingleFileUpload image={userPhoto || ProfileAvatar} imageClass="rounded-full m-3 w-16 h-16" fileInputRef={userPhotoRef}
                      handleChange={setUserPhoto} handleReset={()=>setUserPhoto()}
                    />
                  <input type="text" value={userFields.username} name="username" placeholder="Full Name" onChange={handleUserChange}
                      className="outline-none block m-2 shadow-md border border-gray-400 rounded-3xl p-3"/>
                  <input type="email" placeholder="Your e-mail" value={userFields.email} name="email" inputMode='email' onChange={handleUserChange}
                        className="outline-none block m-2 shadow-md border border-gray-400 rounded-3xl p-3"/>
                  <input type="tel" inputMode='numeric' placeholder="Your mobile number" 
                        value={userFields.phone_number} name="phone_number" onChange={handleUserChange}
                        className=" outline-none block m-2 shadow-md border border-gray-400 rounded-3xl p-3"/>
                  <button className="bg-[#FFC092] shadow-xl px-4 py-1 mx-auto my-2 w-fit rounded-2xl text-lg font-medium">Save</button>
                </div>
                <p className="text-xl font-medium m-2">Password Section</p>
                <div className="bg-white shadow-lg rounded-3xl p-[10px] flex flex-col justify-between m-1">
                  
                  <input type="password" placeholder="Old Password" name="old_password"
                        value={passwordFields.old_password} onChange={handlePasswordChange}
                        className="outline-none block m-2 shadow-md border border-gray-400 rounded-3xl p-3"/>
                  <p className='text-sm underline mx-8'>Forgot password?</p>
                  <input type="password" placeholder="New Password" name="new_password"
                      value={passwordFields.new_password} onChange={handlePasswordChange}
                      className="outline-none block m-2 shadow-md border border-gray-400 rounded-3xl p-3"/>
                  <input type="password" placeholder="Confirm Password" name="confirm_password"
                    value={passwordFields.confirm_password} onChange={handlePasswordChange}
                    className="outline-none block m-2 shadow-md border border-gray-400 rounded-3xl p-3"/>

                  <button className="bg-[#FFC092] shadow-xl px-4 py-1 mx-auto my-2 w-fit rounded-2xl text-lg font-medium">Update Password</button>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

export default AdminProfile