import { useAuth } from 'features/contexts/AuthContext'
import React from 'react'

const AdminDashboard = () => {

  const {user} = useAuth();


  return (
      <div className='mt-10'>
      <p className='text-2xl'>Hello, <span className='font-semibold'>{user.username}</span> </p>
      <p>Here, you can easily manage, track, and analyze all your key data in one place.</p>
      <div className="grid grid-cols-10 my-2">
        <div className='col-span-7'>

          main part

        </div>
        
        <div className='col-span-3'>
          side part
        </div>
      </div>
      </div>
  )
}

export default AdminDashboard