import { Toaster } from '@constants/appConstants';
import axios from 'axios';

const getBaseUrl=()=>{
  return window.location.hostname === 'localhost' ? 'http://localhost:8004/' : 'https://py.catermateai.info/'
}

export const baseURL = getBaseUrl()

// Create an Axios instance with a base configuration
const axiosInstance = axios.create({
  baseURL:  baseURL,  //process.env.REACT_APP_API_BASE_URL || 'http://localhost:8008/', // Set the base URL from environment variables or default
  timeout: 10000, // Set a timeout of 10 seconds for requests
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

// Request interceptor for adding token and handling other request configurations
axiosInstance.interceptors.request.use(
  (config) => {
    // Add token from local storage if available
    const token = sessionStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Optional: dispatch a loading state or perform any setup before request
    // store.dispatch({ type: 'START_LOADING' });

    return config;
  },
  // (error) => Promise.reject(error)
);

// Response interceptor for handling global errors and logging responses
axiosInstance.interceptors.response.use(
  (response) => {
    // Optional: stop a loading state or perform any cleanup
    // store.dispatch({ type: 'STOP_LOADING' });

    return response.data;
  },
  (error) => {
    // Handle errors globally, for example, redirect to login if unauthorized
    if (error.response && error.response.status === 401 && window.location.pathname!=="/login") {
      // Optional: Redirect to login page or refresh token logic
      // window.location.href = '/login';
      window.location.href = "/login";
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      Toaster("error","Token Expired , Please login again");
    }

    // Optionally, show a toast message for errors
    // toast.error(error.response?.data?.message || 'Something went wrong');
    console.log(error,"axios");
    
    Toaster("error", error?.response?.data?.message || error);
    throw error.response;
  }
);

// Optional: Transform response data globally
axiosInstance.defaults.transformResponse = [(data) => {
  try {
    if (data === null || data === undefined) {
      data = null;
    }

    data = data.replace(/undefined/g, 'null').replace(/NaN/g, 'null');
    
    const parsedData = JSON.parse(data);
    return parsedData.data || parsedData; // Adjust based on the structure of your API
  } catch (error) {
    console.log(error);
    throw error;
  }
}];


// Export the instance for use in your application
export default axiosInstance;
