import React,{Fragment} from 'react'
import BotSectionFooter from "@images/BotSectionFooter.png";
import TryOutYourself from "@images/try_out_yourself.png";
import CatermateDemoBot from './CatermateDemoBot';

const TryOutYourselfSection = () => {
  return (
    <Fragment>
        <div className=" relative my-24 max-lg:mx-[12%] ">
        <section id="try-tourself-section" className="relative ">
            <div className="relative grid grid-flow-row lg:grid-flow-col grid-cols-1 xl:grid-cols-2 z-10">
                <div className="relative xl:max-w-[40vw] max-xl:w-[30vw] lg:py-12 lg:px-6 max-xl:hidden">
                    <img src={TryOutYourself} alt="try out yourself" className="relative 2xl:scale-90  "/>
                </div>
                <div className="relative xl:w-[39vw] md:max-w-[90%] lg:max-w-[100%] max-lg:p-2 xsm:w-[150%]">
                    <div className="bg-white rounded-lg max-lg:p-3 lg:p-4 lg:ps-10 2xl:w-[90%]">
                        <h2 className="text-[#C72122] text-xl md:text-2xl lg:text-3xl font-semibold">
                            Try it for yourself!
                        </h2>
                        <p className="text-[1rem] md:text-xl">
                            Chat with our bot to test out the advanced AI Features...
                        </p>
                        <CatermateDemoBot />
                    </div>
                </div>
            </div>
        </section>
        <img src={BotSectionFooter} alt="bot footer" className=" max-xl:hidden relative h-10 w-[100vw] max-md:bottom-52 md:bottom-72 lg:bottom-20 -z-10"/>
        </div>            
    </Fragment>
  )
}

export default TryOutYourselfSection