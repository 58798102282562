import React from 'react'
import Facebook from "@images/Facebook.png";
import Instagram from "@images/Instagram.png";
import LinkedIn from "@images/Linkedin.png";
import Twitter from "@images/Twitter.png";

const Footer = () => {
  return (
    <footer className="mt-14 py-3 max-xsm:left-[75%] xsm:mx-auto max-xsm:w-[150%] bg-white rounded-t-3xl border-gray-400 border-2 relative bottom-0 xsm:left-[50.5%] -translate-x-1/2">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 my-auto">
        <div className='grid grid-flow-col lg:grid-cols-3 max-lg:grid-cols-1 max-lg:grid-flow-row gap-y-3 lg:m-6'>
           <p className="lg:w-fit text-center">Catermate AI</p>
           <div className='flex justify-around font-semibold mx-[20%]'>
              <a href="/#home">Home</a>
              <a href="/#about">About</a>
              <a href="/#features">Features</a>
              <a href="/#pricing">Pricing</a>
           </div>
            <div className='flex lg:justify-end max-lg:justify-center gap-x-4'>
              <img alt="social icon" src={Facebook} className='w-5 h-5'/>
              <img alt="social icon" src={Instagram} className='w-5 h-5'/>
              <img alt="social icon" src={LinkedIn} className='w-5 h-5'/>
              <img alt="social icon" src={Twitter} className='w-5 h-5'/>
            </div>
        </div>
        <hr className=' bg-gray-500 h-[2px]'/>
        <div className='flex justify-between'>
          <p className="max-xsm:text-[0.8rem]">&copy;{new Date().getFullYear()} catermate. All Rights Reserved.</p>
          <p className='lg:-ml-20 max-xsm:text-[0.8rem]'>Privacy Policy</p>
          <p className="max-xsm:text-[0.8rem]">Terms & Conditions</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer